import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React, {useEffect, useState} from "react";
import CreateProjectCard, {CreateTaskCard} from "../components/CreateProjectCard";
import appendIcon from "../components/appendIcon";
import {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import PielersForm from "../../../../../@WUM/core/form-serilizer";
import DynamikModal, {DynamikOffCanvas} from "../../../../../@WUM/core/component/const/DynamikModal";
import {useSelector} from "react-redux";
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
import {getToken} from "../../../../../@WUM/core/component/const/getToken";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import {
    ResponseAllLastActivitiesAll,
    ResponseAllProject,
    ResponseAllProjectColumn,
    ResponseAllProjectParent,
    ResponseAllProjektSwimelanes,
    ResponseAllSubProjektSwimelanes,
    ResponseAllSubProjektSwimelanesLocal,
    ResponseAllTaskSwimelanes, ResponseSingleProject
} from "../../../API/response/ticket";
import IntlMessage from '../../../../../@WUM/core/component/const/IntlMessage'
import Table from 'react-bootstrap/Table';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {refresh, switchdisplay} from "./SingleTask";
import InputGroup from 'react-bootstrap/InputGroup';
import '../css/timeline.css'
import Avatar from "@material-ui/core/Avatar";
import Card from "react-bootstrap/Card";
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import ReactDOM from "react-dom";
import Sortable from "sortablejs";
import {BoardTableSwitcher,allowSaveMove, between2digits, Search} from "./MyProjects";
const MyUnderProjects = (props:{value:any}) => {
    const { projectid,name} = useParams<{ projectid: string,name:string}>()

    let { state } = useLocation();

    let id = (state.projectid !== null && state.projectid !== null ? state.projectid : projectid)

    const Store = useSelector((state: any) => state)
    let y = 1;
    const [fetchData, setFetchData] = useState(0);
    const [pid, setpid] = useState(projectid);
    const [archiv, setArchiv] = useState(0);
    const [del, setDel] = useState(0);

    const  response = ResponseAllProjectParent(id,fetchData,archiv,del)

    const  responseAll = ResponseAllProject(id)

    const singleProject = ResponseSingleProject(projectid);

    let Column = ResponseAllProjectColumn(fetchData)
    const [fetchSortData, setFetchSortData] = useState([]);
    const [filter, setfilter] = useState([]);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const [swimlaneID, SetSwimlaneID] = useState(id);

    const Swimelanes = ResponseAllSubProjektSwimelanes(id,fetchData)
    let Swimelaness = ResponseAllSubProjektSwimelanesLocal(swimlaneID,fetchData)
    let buildSwimelanes = [];
    const [selectswimelane, setswimelane] = useState(0);
    let  firstswimelane;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectcolumn, setSelectcolumn] = useState(0);
    const LastActivities = ResponseAllLastActivitiesAll(fetchData)
    let BuildLastActivities = [];
    let Buildsubproject = [];
    let BuildAllsubproject = [];
    if(pid !== state.projectid){
        setpid(state.projectid)
        setFetchData(Date.now())
    }


    if (typeof response !== "boolean" && response?.length > 0) {
        for (let res = 0; res < response.length; res++) {
            Buildsubproject.push(
                <>
                    <option value={response[res].id}>Subrpoject from {response[res].title}</option>
                </>
            )
        }


    if (typeof responseAll !== "boolean" && responseAll?.length > 0) {
        for (let res = 0; res < responseAll.length; res++) {
            BuildAllsubproject.push(
                <>
                    <option value={responseAll[res].id}>Subrpoject from {responseAll[res].title}</option>
                </>
            )
        }
    }
    }



    const AddedFunc = (taskID:any,ProjectID:any,indiId?:any) => {


        let Dialog = document.getElementById((indiId !== undefined && indiId !== null ? indiId : 'addDialogMember')) as HTMLDivElement;
        Dialog.classList.add('show')
        Dialog.style.display = 'block';


        let Task = document.getElementById('TaskId') as HTMLInputElement;
        if(taskID !== null) {
            if(Task !== null) {
                Task.value = taskID;
            }
        }


        let Project = document.getElementById('ProjectId') as HTMLInputElement;

        if(ProjectID !== null) {
            if(Project !== null){
                Project.value = ProjectID
            }
        }


    }

    if (typeof LastActivities !== "boolean" && LastActivities?.length > 0) {
        for (let la = 0; la < LastActivities.length; la++) {

            if(LastActivities[la].system === '9') {
                BuildLastActivities.push(
                    <>
                        <li className="timeline-item mb-5" style={{padding:'10px'}}>
                            <h5 className="fw-bold"><Avatar className={'float-start'} alt={LastActivities[la].member} src="/static/images/avatar/1.jpg" />
                                <span className={'float-start'} style={{paddingTop:'5px',paddingLeft:'5px'}}><small>{LastActivities[la].member}</small></span>
                                <span className={'float-end pr-3 btn btn-info'} style={{cursor:'default',background:'#23AAE1',color:'white'}}><small><strong>Extern</strong></small></span>
                                <Button variant="primary" className={"pr-3 float-end"} style={{padding:'5px',background:'white',borderColor:'#23AAE1',color:'#23AAE1'}}>
                                    <span className="material-icons" style={{verticalAlign:'middle',marginLeft:'13px'}} onClick={()=>navigate('/task/single/'+LastActivities[la].project_id+'/'+LastActivities[la].task_id)}>
                                    logout
                                </span></Button>
                            </h5>
                            <br style={{clear:'both'}}/>
                            <p className="text-muted mb-2 fw-bold">{LastActivities[la].regard}<br/><small>{LastActivities[la].created_at}</small></p>
                            <p className="text-muted" dangerouslySetInnerHTML={{__html: LastActivities[la].content}} style={{padding:'15px',borderRadius:'10px',border:'1px solid #E5E5E5'}} />
                        </li>

                    </>
                )
            } else {
                BuildLastActivities.push(
                    <>
                        <li className="timeline-item mb-5">
                            <h5 className="fw-bold"><Avatar className={'float-start'} alt={LastActivities[la].member} src="/static/images/avatar/1.jpg">
                                MU
                            </Avatar><span className={'float-start'} style={{paddingTop:'5px',paddingLeft:'5px'}}><small>{LastActivities[la].member}</small></span>
                                <Button variant="primary" className={"pr-3 float-end"} style={{padding:'5px',background:'white',borderColor:'#23AAE1',color:'#23AAE1'}}>
                                    <span className="material-icons" style={{verticalAlign:'middle',marginLeft:'13px'}} onClick={()=>navigate('/task/single/'+LastActivities[la].project_id+'/'+LastActivities[la].task_id)}>
                                    logout
                                </span></Button> </h5>
                            <br style={{clear:'both'}}/>
                            <p className="text-muted mb-2 fw-bold">{LastActivities[la].regard}<br/><small>{LastActivities[la].created_at}</small></p>
                            <p className="text-muted" dangerouslySetInnerHTML={{__html: LastActivities[la].content}} style={{padding:'15px',borderRadius:'10px',border:'1px solid #E5E5E5'}} />
                        </li>

                    </>
                )
            }





        }
    }
    const CloseDynmaikModal = () => {
        handleClose();
        setSelectcolumn(0)
    }

    const openNew = (columnID:any) => {
        handleShow()
        setSelectcolumn(columnID)
    }

    const EditSwimelane = (id:any,color:any,name:any) => {
        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                            editSwimelane(e,CloseMember,'addDialogdynamik')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Edit swimlane</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                <Row>
                                    <Col className={'col-10'}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" id={'editSwimelanename'} name={'name'} required={true} defaultValue={name}/>
                                    </Form.Group></Col>
                                    <Col className={'col-2'}> <Form.Label htmlFor="exampleColorInput">Color</Form.Label>
                                        <Form.Control
                                            type="color"
                                            name={'color'}
                                            id={'editSwimelanecolor'}
                                            defaultValue={color}
                                        /></Col>
                                </Row>
                                <input name={'id'} type="hidden" id="id" className="form-control" defaultValue={id} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}>Close
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >Edit swimlane</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </>
        )}
    const saveSwimelane = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            name: formdata.get('name'),
            color: formdata.get('color'),
            mainproject: id
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/new/swimelanes/project/', requestOptions)
            .then((response) => response.text())
            .then((result) => successFormswimelane(result,CloseMember,CloseMemberID,[{id:'saveSwimelanename',value:''},{id:'saveSwimelanecolor',value:'#10B82B'}]))
            .catch((error) => errorFormswimelane(error,CloseMember,CloseMemberID))
    }




    const editSwimelane = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            name: formdata.get('name'),
            color: formdata.get('color')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/edit/swimelanes/'+formdata.get('id')+'/project/', requestOptions)
            .then((response) => response.text())
            .then((result) => successFormswimelane(result,CloseMember,CloseMemberID,[{id:'saveSwimelanename',value:''},{id:'saveSwimelanecolor',value:'#10B82B'}]))
            .catch((error) => errorFormswimelane(error,CloseMember,CloseMemberID))
    }



    const successFormswimelane = (result:any,close:any,mode?:any,indiclear?:any) => {
        setFetchData(Date.now())
        if(mode !== undefined && mode !== null) {
            close(mode,indiclear)
        } else {
            close()
        }

    }

    const errorFormswimelane = (error:any,close:any,mode?:any)=> {
        if(mode !== undefined && mode !== null) {
            close(mode)
        } else {
            close()
        }
    }

    const AddedFunc2 = (form?:any,footer?:any,mode?:any) => {


        let Dialog = document.getElementById('addDialogdynamik') as HTMLDivElement;
        Dialog.classList.add("show");
        Dialog.style.display = 'block';
        if(form !== undefined) {
            let newElement = form
            let temp = document.createElement('div')
            ReactDOM.render(newElement, temp)
            let ele = document.getElementById('injectModalTask') as HTMLDivElement
            if(ele !== null) {
                ele.innerHTML = ''
                ele.prepend(temp)
            }
        }
    }
    if (typeof response !== "boolean" && response?.length > 0) {
        if (Swimelanes !== true && typeof Swimelanes !== "boolean" && Swimelanes?.length > 0) {


            let Swimelane = [];
            firstswimelane = Swimelanes[0].id
            for (let sw = 0; sw < Swimelanes.length; sw++) {
                let TMPbuildCreateTaskCard = [];
                let SwimelaneCounter = 0;
                let Swimelaneid = Swimelanes[sw].id;
                let background = Swimelanes[sw].color

                if (Column !== true && typeof Column !== "boolean" && Column?.length > 0) {

                    /* @ts-ignore */
                    let TMPCreateTaskCard = [];

                    for (let c = 0; c < Column.length; c++) {
                        let buildCreateTask = [];
                        let counter = 0;
                        SwimelaneCounter = SwimelaneCounter + 1
                        if (typeof response !== "boolean" && response?.length > 0
                        ) {
                            let Data = (fetchSortData.length === 0 ? response : fetchSortData);
                            for (let x = 0; x < Data.length; x++) {
                                if (Data[x].column.id === Column[c].id && Swimelanes[sw].id == Data[x].swimlane_id) {
                                    counter++
                                    let nowcal:number = (100 / (Data[x].allTask as number) ) * (Data[x].doneTask as number)
                                    buildCreateTask.push(<>
                                        <CreateProjectCard Store={Store} id={Data[x].id} Buildsubproject={Buildsubproject} BuildAllsubproject={BuildAllsubproject} setFetchData={setFetchData} description={Data[x].description} parent={Data[x].parent}
                                                           isMain={Data[x].is_main} avatarID={1} SetSwimlaneID={SetSwimlaneID}
                                                           position={Data[x].position} swimelane={Swimelanes[sw].id} delete={Data[x].delete} archiv={Data[x].archiv}
                                                           background={(Data[x].color !== undefined && Data[x].color !== null ? Data[x].color : 'white')}
                                                           title={Data[x].title} now={(!isNaN(nowcal) ? nowcal.toFixed(0) : 0)} variant={between2digits((!isNaN(nowcal) ? nowcal : 0))}
                                                           status={'Open'} daysColor={'248, 98, 27'} daysLeft={2} task={Data[x].doneTask}
                                                           allTask={Data[x].allTask}
                                                           teamMember={true} date={Data[x].created_at}/>
                                    </>)
                                }
                            }

                        }

                        let cid = Column[c].id;

                        TMPbuildCreateTaskCard.push(
                            <>
                                <Col>
                                    <h6 className={'text-uppercase'} style={{
                                        borderBottom: '2px solid #F8621B',
                                        paddingBottom: '15px'
                                    }}>{Column[c].name} ({counter})</h6>
                                    <div className={'nested-sortable'} data-columnID={cid}
                                         data-swimelane={Swimelanes[sw].id}
                                         style={{minHeight: (buildCreateTask.length > 0 ? 'auto' : '60px'),height:'80%'}}>
                                        {buildCreateTask}
                                    </div>
                                    <br/>
                                    <p onClick={() => openNew(cid)} className={'text-center'} style={{cursor:'pointer'}}>
                            <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                                add
                            </span>
                                        Add new project
                                    </p>
                                </Col>
                            </>
                        )

                    }
                    /* @ts-ignore */
                    TMPbuildCreateTaskCard.push(TMPCreateTaskCard)
                }
                const setterSwimelane = (id: any, closeAnother?: any, displayState?: any) => {
                    switchdisplay(id, closeAnother, displayState)
                    let swimelane = document.getElementById('swimelane_' + Swimelaneid) as HTMLDivElement;
                    if (swimelane !== null) {
                        if (swimelane.style.display === 'none') {
                            let Icon = document.getElementById('expand_swimelane_' + Swimelaneid) as HTMLSpanElement;
                            if (Icon !== null) {
                                Icon.innerHTML = 'expand_more'
                            }
                        } else {
                            let Icon = document.getElementById('expand_swimelane_' + Swimelaneid) as HTMLSpanElement;
                            if (Icon !== null) {
                                Icon.innerHTML = 'expand_less'
                            }
                        }
                    }
                }
                let swimlanename = Swimelanes[sw].name
                Swimelane.push(
                    <>

                        <div className={'swimelane'} style={{background: background,borderRadius:'0px',marginLeft:'10px',width:'97.4%'}}>
                            <p  >
                                 <span className="material-icons float-start" style={{cursor:'pointer'}} id={'expand_swimelane_'+Swimelaneid} onClick={()=>setterSwimelane('swimelane_'+Swimelaneid,null,'flex')}>
                                    expand_less
                                </span>
                                <span className={'ml-1 float-start'} style={{cursor:'pointer'}} onClick={()=>setterSwimelane('swimelane_'+Swimelaneid,null,'flex')}>{swimlanename} ({SwimelaneCounter})</span>

                                <Button variant="primary" className={'btn-sm float-end'} style={{height:'20px',width:'20px',padding:'0px',marginTop:'2px'}}>
                            <span className="material-icons " style={{fontSize:'18px'}}
                                  onClick={(e) => AddedFunc2(EditSwimelane(Swimelaneid,background,swimlanename))}>
                            edit
                        </span></Button>
                            </p>
                        </div>


                        <Row id={'swimelane_' + Swimelaneid} style={{display: 'flex'}}>
                            {TMPbuildCreateTaskCard}
                        </Row>
                    </>
                )
            }
            buildSwimelanes.push(
                <>
                    {Swimelane}
                </>
            )
        }
    }
    const handlesubmit = (e:any) => {
        const form = e.currentTarget
        e.preventDefault()
        let Data = init2(form)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(Data);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +"yoptimize/new/project/", requestOptions)
            .then(response => response.text())
            .then(result => successForm(result))
            .catch(error => errorForm(error));
    };

    const successForm = (result:any) => {
        setFetchData(Date.now())
        setSelectcolumn(0)
        handleClose()
    }

    const errorForm = (error:any)=> {
        setSelectcolumn(0)
        handleClose()
    }

    const FormModal = [
        {
            formType: 'input',
            label: IntlMessageValue('de', 'common.title', Store),
            required: true,
            type: 'text',
            name: 'title',
            id:"newCalenderTitle",
            value: ''
        },
        {
            formType: 'textarea',
            label:  IntlMessageValue('de', 'common.description', Store),
            type: 'textarea',
            max: 800,
            name: 'description',
            id: 'newCalenderDescription',
            placeholder: '',
            row:6,
            value: ''
        }
    ]



    const CloseMember = (indiId?:any,indclear?:any) => {
        let Dialog = document.getElementById((indiId !== undefined && indiId !== null ? indiId : 'addDialogMember')) as HTMLDivElement;
        Dialog.style.display = 'none';

        if(indclear !== null && indclear !== undefined) {
            for(let i =0;i<indclear.length;i++) {
                let tmp = document.getElementById(indclear[i].id) as HTMLInputElement;

                if(tmp !== null) {
                    tmp.value = indclear[i].value;
                }
            }
        }

        let Task = document.getElementById('TaskId') as HTMLInputElement;

        if(Task !== null) {
            Task.value = '';
        }

        let Project = document.getElementById('ProjectId') as HTMLInputElement;

        if(Project !== null){
            Project.value = ''
        }
    }
    const setter = () => {

        for (let x = 1; x < 17; x++) {
            if(y === x) {
                if(document.getElementById(x + 'avatarGroupadded') as HTMLDivElement !== null) {

                } else
                if (document.getElementById(x + 'avatarGroupadded') as HTMLDivElement === null) {
                    appendIcon(true, x + 'avatarGroup',AddedFunc,x,1)

                }
            }
            y++;
        }
    }

    const Configuration = {
        tableHeader: [
            <IntlMessage Store={Store} messageId="common.id" />,
            <IntlMessage Store={Store} messageId="common.title" />,
            <IntlMessage Store={Store} messageId="common.description" />,
            <IntlMessage Store={Store} messageId="Column" />,
            <IntlMessage Store={Store} messageId="Team" />,
        ],
        tableHeaderType: ['integer','string','string','string','string'],
        tableContent: ['id','title','description','column.name','member']
        }

    useEffect(setter, [setter]);
    useEffect(() => {

        let SwimLaneHolder = [];
        let ele = document.getElementById('MoveHolderProject') as HTMLDivElement
        if(ele !== null) {
            ele.innerHTML = ''
        }
        let newElement = <></>
        let temp = document.createElement('option') as HTMLOptionElement;
        temp.value = '';
        temp.label = 'Select Swimlane';
        ReactDOM.render(newElement, temp)
        if(ele !== null) {
            ele.append(temp)
        }
        let localSwimLanes = JSON.parse(localStorage.getItem('swimlanesSubprojectLocal') as string)

        for (let us = 0; us < localSwimLanes?.length; us++) {
            /* SwimLaneHolder.push(
                 <>
                     <option value={localSwimLanes[us].id}>{localSwimLanes[us].name}</option>
                 </>
             )*/

            let newElement = <></>
            let temp = document.createElement('option') as HTMLOptionElement;
            temp.value = localSwimLanes[us].id;
            temp.label = localSwimLanes[us].name;
            ReactDOM.render(newElement, temp)
            if(ele !== null) {
                ele.append(temp)
            }
        }
        if(localSwimLanes?.length === 0) {
            let newElementSwim = <></>
            let tempSwim = document.createElement('option') as HTMLOptionElement;
            tempSwim.value = '0';
            tempSwim.label = 'Parst into new Swimlane Intern';
            ReactDOM.render(newElementSwim, tempSwim)
            if(ele !== null) {
                ele.append(tempSwim)
            }
        }


        let eles = document.getElementById('MoveHolderProjectMain') as HTMLDivElement
        if(eles !== null) {
            eles.innerHTML = ''
        }
        let newElements = <></>
        let temps = document.createElement('option') as HTMLOptionElement;
        temps.value = '';
        temps.label = 'Select Swimlane';
        ReactDOM.render(newElements, temps)
        if(eles !== null) {
            eles.append(temps)
        }
        let localSwimLaness = JSON.parse(localStorage.getItem('swimlanesSubprojectLocal') as string)

        for (let uss = 0; uss < localSwimLaness?.length; uss++) {

            let newElements = <></>
            let temps = document.createElement('option') as HTMLOptionElement;
            temps.value = localSwimLaness[uss].id;
            temps.label = localSwimLaness[uss].name;
            ReactDOM.render(newElements, temps)
            if(eles !== null) {
                eles.append(temps)
            }
        }

        if(localSwimLaness?.length === 0) {
            let newElementSwim = <></>
            let tempSwim = document.createElement('option') as HTMLOptionElement;
            tempSwim.value = '0';
            tempSwim.label = 'Parst into new Swimlane Intern';
            ReactDOM.render(newElementSwim, tempSwim)
            if(eles !== null) {
                eles.append(tempSwim)
            }
        }

        let nestedSortables = document.getElementsByClassName('nested-sortable')
        for (var i = 0; i < nestedSortables.length; i++) {
            new Sortable(nestedSortables[i] as HTMLElement, {
                group: 'nested',
                animation: 150,
                fallbackOnBody: true,
                swapThreshold: 0.65,
                onEnd: (evt) => {
                    let newcid;
                    let oldcid;
                    let projectIDmove;

                    oldcid = ((evt.from as HTMLDivElement).dataset.columnid);
                    (evt.from as HTMLDivElement).style.minHeight = '60px';
                    newcid = ((evt.to as HTMLDivElement).dataset.columnid)

                    projectIDmove = (evt.clone as HTMLDivElement).dataset.projectid
                    if(oldcid === newcid) {
                        console.log('kein Fetch')
                    } else {
                        var raw = JSON.stringify({
                            lastupdatefrom: localStorage.getItem('username'),
                            column_id: newcid
                        })


                        var requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' +
                                    JSON.parse(localStorage.getItem('token') as string)
                            },
                            body: raw
                        }


                        fetch(ApiRoot()+'yoptimize/project/'+projectIDmove+'/setColumn/oldCid/'+oldcid+'/', requestOptions)
                            .then((response) => response.text())
                            .then((result) => console.log(result))
                            .catch((error) => console.log(error))
                    }


                    let SortList = document.getElementsByClassName('nested-sortable')

                    for(let sort = 0; sort < SortList.length;sort++) {
                        let SortListElement = SortList[sort].childNodes

                        for(let element =0;element<SortListElement.length;element++){
                            (SortListElement[element] as HTMLDivElement).dataset.position = ""+element+"";
                            (SortListElement[element] as HTMLDivElement).dataset.swimelane = ""+(SortList[sort] as HTMLDivElement).dataset.swimelane+"";
                            let positionColumn = (SortList[sort] as HTMLDivElement).dataset.columnid
                            let newPosition = (SortListElement[element] as HTMLDivElement).dataset.position
                            let PProjectid = (SortListElement[element] as HTMLDivElement).dataset.projectid
                            let Pswimlane_id = (SortListElement[element] as HTMLDivElement).dataset.swimelane

                            var rawPosition = JSON.stringify({
                                position: newPosition,
                                swimlane_id: Pswimlane_id
                            })
                            var requestOptionsPosition = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization:
                                        'Bearer ' +
                                        JSON.parse(localStorage.getItem('token') as string)
                                },
                                body: rawPosition
                            }

                            fetch(ApiRoot()+'yoptimize/project/'+PProjectid+'/setPosition/Cid/'+positionColumn+'/', requestOptionsPosition)
                                .then((response) => response.text())
                                .then((result) => console.log(result))
                                .catch((error) => console.log(error))
                        }
                    }

                },
                onUpdate: (evt) => {

                    // Sortierung
                    let SortList = document.getElementsByClassName('nested-sortable')

                    for(let sort = 0; sort < SortList.length;sort++) {
                        let SortListElement = SortList[sort].childNodes
                        for(let element =0;element<SortListElement.length;element++){
                            (SortListElement[element] as HTMLDivElement).dataset.position = ""+element+"";
                            (SortListElement[element] as HTMLDivElement).dataset.swimelane = ""+(SortList[sort] as HTMLDivElement).dataset.swimelane+"";
                        }
                    }
                },

                onClone: (evt) => {

                    // Sortierung
                    let SortList = document.getElementsByClassName('nested-sortable')

                    for(let sort = 0; sort < SortList.length;sort++) {
                        let SortListElement = SortList[sort].childNodes
                        for(let element =0;element<SortListElement.length;element++){
                            (SortListElement[element] as HTMLDivElement).dataset.position = ""+element+"";
                            (SortListElement[element] as HTMLDivElement).dataset.swimelane = ""+(SortList[sort] as HTMLDivElement).dataset.swimelane+"";
                        }
                    }
                }
            })
        }



    })
    if(fetchData !== undefined && typeof singleProject !== "boolean" && singleProject?.length > 0 ) {

    return(
        <>
            <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>
                <h3>{singleProject[0].title} - SubProjects</h3>
                <Link className={'btn btn-info float-start'} state={{ projectid: singleProject[0].parent }} to={(singleProject[0].parent === "0" ? '/myprojects/new/projects/1' : '/myprojects/new/projects/1/'+singleProject[0].parent+'/test')} style={{background:'white',borderColor:'#23AAE1',color:'#23AAE1'}}>
                    Back
                </Link>
                <Search navigate={navigate} />
                {refresh(setFetchData)}
                <DynamikOffCanvas openButtonLabel={<><span className="material-icons" style={{verticalAlign: 'bottom'}}>
                    add
                    </span>
                    Add new project</>}
                              saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                              closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                              title={'Create new Project'}
                              SaveID={'CreateNewSubProjects'}
                              saveDisabled={true}
                              ClassOpenButton={'float-end'}
                              ClassSaveButton={'float-end'}
                              body={<>{PielersForm(FormModal)}
                              <Form.Control name={'column_id'} type="hidden" defaultValue={(selectcolumn !== 0 ? selectcolumn : 2)} />
                                  <Form.Control name={'swimlane_id'} type="hidden"  defaultValue={(selectswimelane !== 0 ? selectswimelane : firstswimelane)} />
                                  <Row>
                                      <Col className={'col-10'}>
                                          <Form.Label>to make a subproject of</Form.Label>
                                          <Form.Select name={'parent'} aria-label="Default select example" onChange={(e) => swimmLane(e,SetSwimlaneID,setFetchData)}>
                                              <option label={"Select project"} value={''}></option>
                                              <option label={"as normal project"} value={'0'}></option>
                                              {Buildsubproject}
                                          </Form.Select></Col>
                                      <Col className={'col-2'}> <Form.Label>Farbe</Form.Label>
                                          <Form.Control
                                              type="color"
                                              name={'color'}
                                              id={'saveSwimelanecolor'}
                                              defaultValue="#FFFFFF"
                                          /></Col>
                                  </Row>
                                  <br/>
                                  <Form.Select name={'swimlane_id'} id={'MoveHolderProjectMain'} aria-label="Default select example"
                                               required={true}>
                                  </Form.Select>
                                  <br/>
                                  <Form.Check
                                      className={'text-danger'}
                                      type={'checkbox'}
                                      label={'Warning, all tasks of the main project are no longer displayed. Alternatively, you can create a new empty project for better organization'}
                                      id={`disabled-default-`}
                                      onClick={(e)=> allowSaveMove(e,'CreateNewSubProjects')}
                                  />
                                  <br/>
                              </>}
                              handlesubmit={handlesubmit}
                              handleShow={handleShow}
                              show={show}
                              setShow={setShow}
                              handleClose={CloseDynmaikModal}
                />
                <Button variant="outline-primary" className={'float-end ml-3'} style={{position:'relative',right:'5px',zIndex:'96'}}
                        onClick={(e) => AddedFunc(null,null,'addDialogSwimelanes')}>
                    <span className="material-icons" style={{verticalAlign:'bottom'}}>
                    add
                </span>
                    New swimlane
                </Button>
                <br style={{clear:'both'}}/>
                <br/>
                <Row>
                    <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Title</InputGroup.Text>
                        <Form.Control type="text"
                                      onChange={(e)=>FilterResponse(response,setFetchSortData,setFetchData,'title',e,filter,setfilter)}/>
                    </InputGroup>
                    </Col>
                    <Col >
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Description</InputGroup.Text>
                        <Form.Control type="text"
                                      onChange={(e)=>FilterResponse(response,setFetchSortData,setFetchData,'description',e,filter,setfilter)}/>
                    </InputGroup>
                    </Col>
                </Row>
                <br/>
               <BoardTableSwitcher boardId={'board'} TableID={'TableMakerSupport'}  HistoryID={'history'}  archiv={setArchiv} del={setDel} />
                <br/>
                <div  id={'board'} style={{display:'block'}}>
                <Row>
                    {buildSwimelanes}
                </Row>
                </div>
                <TableMakerSupport id={'TableMakerSupport'}
                                   Configuration={Configuration}
                                   responseOld={response}
                                   response={fetchSortData}
                                   setFetchSortData={setFetchSortData}
                                   setFetchData={setFetchData}
                                   navigate={navigate}
                                   url={'/mytask/new/projects/[id]/[title]'}
                                   params={['id','title']}

                />
            </div>
            <div id={'history'} style={{display:'none',padding:'20px'}}>
                <div className={'tasktimeline'}>
                    <p><strong>Task Timeline</strong>   <span style={{color:'#23AAE1',textDecoration:'underline'}}>History</span></p>
                </div>
                <Card style={{borderRadius:'0px'}}>

                    <Card.Body>
                        <section >
                            <ul className="timeline">
                                {BuildLastActivities}
                            </ul>
                        </section>
                    </Card.Body>

                </Card>
            </div>
            <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                 id="addDialogMember" style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4">Added Team Member</div>
                            <button type="button" className="btn-close" aria-label="Close" onClick={()=>CloseMember()} />
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <input name={'ProjectId'} type="hidden" id="ProjectId" className="form-control" value="" />
                                        <Form.Select name={'MemberId'} aria-label="Default select example" >
                                            <option></option>
                                            <option value="1">Max Mustermann</option>
                                            <option value="2">John Doe</option>
                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={()=>CloseMember()}>Close</button>
                            <button type="button" className="btn btn-primary float-end">Added Member</button>
                        </div>
                    </div>
                </div>
            </div>
            <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                 id="addDialogSwimelanes" style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                            saveSwimelane(e,CloseMember,'addDialogSwimelanes')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Add swimlane</div>
                                <button type="button" className="btn-close" aria-label="Close" onClick={()=>CloseMember('addDialogSwimelanes',[{id:'saveSwimelanename',value:''},{id:'saveSwimelanecolor',value:'#10B82B'}])} />
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col">
                                        <div className="mb-3">
                                            <Row>
                                                <Col className={'col-10'}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" id={'saveSwimelanename'} name={'name'} required={true}/>
                                                </Form.Group></Col>
                                                <Col className={'col-2'}> <Form.Label htmlFor="exampleColorInput">Color</Form.Label>
                                                    <Form.Control
                                                        type="color"
                                                        name={'color'}
                                                        id={'saveSwimelanecolor'}
                                                        defaultValue="#10B82B"
                                                    /></Col>
                                            </Row>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={()=>CloseMember('addDialogSwimelanes',[{id:'saveSwimelanename',value:''},{id:'saveSwimelanecolor',value:'#10B82B'}])}>Close</button>
                                <button type="submit" className="btn btn-primary float-end">Add swimlane</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                 id="addDialogdynamik" style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>

                <div className="offcanvas-body">
                    <div id="injectModalTask"></div>
                </div>
            </div>
        </>
    )
} else {
    return(
        <></>
    )
    }
}

export default MyUnderProjects;

export const FilterResponse = (Data:any,setFetchSortData:any,setFetchData:any,Field:any,search:any,filter:any, setfilter:any) => {

    let Filter = Data
    /* @ts-ignore */
    Filter = Filter.filter( record => record[Field]?.includes(search.target.value))
    for(let x = 0;x<filter.length;x++) {
        if(filter[x] !== undefined && filter[x] !== null){
            if (filter[x].field !== Field) {
                /* @ts-ignore */
                Filter = Filter.filter(record => record[filter[x].field]?.includes(filter[x].search))
            }
        }

    }
    let add = true;
    let updateID = 0
    for(let x = 0;x<filter.length;x++) {
        if(filter[x] !== undefined && filter[x] !== null) {
            if (filter[x].field === Field) {
                add = false;
                updateID = x;
                if (search.target.value === '') {
                    delete filter[x]
                    filter.length = filter.length-1
                } else {
                    filter[x].search = search.target.value;
                }

            }
        } else {
            add = false;
            delete filter[x]
            filter.length = filter.length-1
        }

    }
    if(add === true) {
        setfilter([
            ...filter,
            { id: filter.length + 1, field: Field,search:search.target.value }
        ]);
    } else {
        setfilter([
            ...filter
        ]);
    }

    setFetchSortData(Filter)
    setFetchData(Date.now())
    return Filter;
}


export const TableMakerSupport = (props:{id:any;Configuration:any,responseOld:any,response:any;setFetchSortData:any,setFetchData:any,navigate:any,url:any,params:any}) =>{



    let Data = (props.response.length === 0 ? props.responseOld : props.response);
    let Header = [];


    const Sort = (sortmode:any,sorttype:any,part:any) => {

        let Sortback;

        if(sortmode === 'asc') {
            Sortback = Data.sort(function(a:any, b:any) {
                switch (sorttype) {
                    case 'string':
                        if(part.includes('.') === true) {
                            return (a[part.split('.')[0]][part.split('.')[1]] > b[part.split('.')[0]][part.split('.')[1]]) ? 1 : ((a[part.split('.')[0]][part.split('.')[1]] < b[part.split('.')[0]][part.split('.')[1]]) ? -1 : 0)
                        } else {
                            return (a[part] > b[part]) ? 1 : ((a[part] < b[part]) ? -1 : 0)
                        }

                    case 'date':
                        if(part.includes('.') === true) {
                            return new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime()
                        } else {
                            return new Date(a[part]).getTime() - new Date(b[part]).getTime()
                        }

                    case 'integer':
                        if(part.includes('.') === true) {
                            return  a[part.split('.')[0]][part.split('.')[1]] - b[part.split('.')[0]][part.split('.')[1]]
                        } else {
                            return  a[part] - b[part]
                        }

                }
            });
        } else if(sortmode === 'desc') {
            Sortback = Data.sort(function(a:any, b:any) {
                switch (sorttype) {
                    case 'string':
                        if(part.includes('.') === true) {
                            return (b[part.split('.')[0]][part.split('.')[1]] > a[part.split('.')[0]][part.split('.')[1]]) ? 1 : ((b[part.split('.')[0]][part.split('.')[1]] < a[part.split('.')[0]][part.split('.')[1]]) ? -1 : 0)
                        } else {
                            return (b[part] > a[part]) ? 1 : ((b[part] < a[part]) ? -1 : 0)
                        }

                    case 'date':
                        if(part.includes('.') === true) {
                            return new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime()
                        } else {
                            return new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime()
                        }

                    case 'integer':
                        if(part.includes('.') === true) {
                            return  b[part.split('.')[0]][part.split('.')[1]] - a[part.split('.')[0]][part.split('.')[1]]
                        }else{
                            return  b[part] - a[part]
                        }

                }
            });
        }

        props.setFetchSortData(Sortback)
        props.setFetchData(Date.now())
    }

    for(let h=0;h<props.Configuration.tableHeader.length;h++) {
        Header.push(
            <>
                <th>{props.Configuration.tableHeader[h]}
                    <span className="material-icons" style={{verticalAlign:'middle'}} onClick={()=>Sort('asc',props.Configuration.tableHeaderType[h],props.Configuration.tableContent[h])}>
                    expand_less
                    </span>
                    <span className="material-icons" style={{verticalAlign:'middle'}}  onClick={()=>Sort('desc',props.Configuration.tableHeaderType[h],props.Configuration.tableContent[h])}>
                    expand_more
                    </span>
                    </th>
            </>
        )
    }


    let Content = [];

    for(let r=0;r<Data.length;r++) {

        let ContentHolder = [];

        for(let ch=0;ch<props.Configuration.tableContent.length;ch++) {
            if(props.Configuration.tableContent[ch].includes('#') === true) {
                ContentHolder.push(
                    <>
                        <td>{Data[r][props.Configuration.tableContent[ch].split('.')[0]][props.Configuration.tableContent[ch].split('.')[1]][props.Configuration.tableContent[ch].split('.')[2]]}</td>

                    </>
                )

            } else if(props.Configuration.tableContent[ch].includes('.') === true) {
                ContentHolder.push(
                    <>
                        <td>{Data[r][props.Configuration.tableContent[ch].split('.')[0]][props.Configuration.tableContent[ch].split('.')[1]]}</td>

                    </>
                )

            } else {
                ContentHolder.push(
                    <>
                        <td>{Data[r][props.Configuration.tableContent[ch]]}</td>

                    </>
                )
            }

        }

        let link = props.url;
        for(let p=0;p<props.params.length;p++){
            link = link.replace('['+props.params[p]+']',Data[r][props.params[p]])
        }

        Content.push(<>
            <tr onDoubleClick={()=> props.navigate(link)}>
                {ContentHolder}
            </tr>
        </>)
    }




    return(
        <Table striped bordered hover id={props.id} style={{display:'none'}}>
            <thead>
            <tr>
                {Header}
            </tr>
            </thead>
            <tbody>
            {Content}
            </tbody>
        </Table>
    )
}

export const swimmLane = (e:any,SetSwimlaneID:any,setFetchData:any) => {

        SetSwimlaneID(e.target.value)

        setFetchData(Date.now())

}

