import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Avatar from "@material-ui/core/Avatar";
import InputGroup from 'react-bootstrap/InputGroup';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React, {useEffect, useRef, useState} from "react";
import {Link,useNavigate,useParams} from "react-router-dom";
import {
    ResponseAllLastActivities,
    ResponseAllProject,
    ResponseAllTaskInternall, ResponseAllTaskState, ResponseAllTaskSwimelanes, ResponseAllTResponsibility,
    ResponseTaskDetail
} from "../../../API/response/ticket";
import PielersForm from '../../../../../@WUM/core/form-serilizer'
import Button from 'react-bootstrap/Button';
import ReactDOM from "react-dom";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import { SpinnerJSX } from '../../../../../@WUM/core/component/const/Spinner'
import {useDispatch, useSelector} from "react-redux";
import SunEditorConfig from '../../components/SuneditorConfig'
import SunEditor from 'suneditor-react'
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Markdown from 'react-markdown'
import MDEditor from "@uiw/react-md-editor";
import MarkdownEditor from '@uiw/react-markdown-editor';
import remarkGfm from 'remark-gfm'
/* @ts-ignore */
import mermaid from "mermaid";

/* @ts-ignore */
import Gluejar from 'react-gluejar'
import {notify_bucket} from "../../../../../@WUM/core/component/const/notification.service";
import {error} from "../../../../../@WUM/core/component/const/error.services";
import '../css/timeline.css'
import {Search} from "./MyProjects";
import {dateFormat} from "../../../../../@WUM/core/component/const/dateFormat";
import {AppDispatch} from "../../../../../store";
import * as actionSingleTask from "../store/Actions/Singletask/SingleTaskAction";
const SingleTask = (props:{value:any}) => {
    const { id,taskid } = useParams<{ id: string,taskid:string }>()
    const Store = useSelector((state: any) => state)
    //console.log(Store.SingleTasks)
    let ProjectId;
    let nosearch;
    if(process.env.REACT_APP_MODE_TICKET_ID !== undefined && process.env.REACT_APP_MODE_TICKET_ID !== null
        && process.env.REACT_APP_MODE_TICKET_NAME !== undefined && process.env.REACT_APP_MODE_TICKET_NAME !== null) {
        ProjectId = process.env.REACT_APP_MODE_TICKET_ID;
        nosearch = false;
    } else {
        ProjectId = id;
        nosearch = true;
    }


    const dispatch: AppDispatch = useDispatch()
    const [fetchData, setFetchData] = useState(0);
    const [value, setValue] = useState("");
    const [commentEdit, setCommentEdit] = useState( "0");
    const response = ResponseTaskDetail(ProjectId,taskid,fetchData,setValue,commentEdit,dispatch)
    const LastActivities = ResponseAllLastActivities(ProjectId,taskid,fetchData)
    const [fetchDataID, setFetchDataID] = useState(ProjectId);
    const FetchTask = ResponseAllTaskInternall(fetchDataID)
    const AllState = ResponseAllTaskState();

    let AllUSer = ResponseAllTResponsibility(fetchData)
    /* @ts-ignore */
    let UserHolder = [];
    let StateHolder = [];
    let StackBadgeHolder = [];
    const navigate = useNavigate();

    if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
        for (let us = 0; us < AllUSer?.length; us++) {
            UserHolder.push(
                <>
                    <option value={AllUSer[us].username}>{AllUSer[us].username}</option>
                </>
            )
        }
    }

    if (AllState !== true && typeof AllState !== "boolean" && AllState?.length > 0) {
        for (let us = 0; us < AllState?.length; us++) {
            StateHolder.push(
                <>
                    <option value={AllState[us].id}>{AllState[us].state}</option>
                </>
            )
        }
    }

    const [swimlaneID, setSwimlaneID] = useState('0');


    const setSwim = (e:any) => {
        setSwimlaneID(e.target.value)
        setFetchData(Date.now())
    }
    let Swimelanes = ResponseAllTaskSwimelanes(swimlaneID,fetchData)

    const ALLProject = ResponseAllProject(fetchData);



    let BuildComments = [];
    let BuildAttachtment = [];
    let BuildExternalConnections = [];
    let BuildInternalConnections = [];
    let BuildSubTask = [];
    let BuildLastActivities = [];

    if (typeof LastActivities !== "boolean" && LastActivities?.length > 0) {
        for (let la = 0; la < LastActivities.length; la++) {

            if(LastActivities[la].system === '9') {
                BuildLastActivities.push(
                    <>
                        <li className="timeline-item mb-5" style={{padding:'10px'}}>
                            <h5 className="fw-bold"><Avatar className={'float-start'} alt={LastActivities[la].member} src="/static/images/avatar/1.jpg" />
                                <span className={'float-start'} style={{paddingTop:'5px',paddingLeft:'5px'}}><small>{LastActivities[la].member}</small></span>
                                <span className={'float-end pr-3 btn btn-info'} style={{cursor:'default',background:'#23AAE1',color:'white'}}><small><strong>Extern</strong></small></span>
                                <Button variant="primary" className={"pr-3 float-end"} style={{padding:'5px',background:'white',borderColor:'#23AAE1',color:'#23AAE1'}}>
                                    <span className="material-icons" style={{verticalAlign:'middle',marginLeft:'13px'}} onClick={()=>navigate('/task/single/'+LastActivities[la].project_id+'/'+LastActivities[la].task_id)}>
                                    logout
                                </span></Button>
                            </h5>
                            <br style={{clear:'both'}}/>
                            <p className="text-muted mb-2 fw-bold">{LastActivities[la].regard}<br/><small>{LastActivities[la].created_at}</small></p>
                            <p className="text-muted" dangerouslySetInnerHTML={{__html: LastActivities[la].content}} style={{padding:'15px',borderRadius:'10px',border:'1px solid #E5E5E5'}} />
                        </li>

                    </>
                )
            } else {
                BuildLastActivities.push(
                    <>
                        <li className="timeline-item mb-5">
                            <h5 className="fw-bold"><Avatar className={'float-start'} alt={LastActivities[la].member} src="/static/images/avatar/1.jpg">
                                MU
                            </Avatar><span className={'float-start'} style={{paddingTop:'5px',paddingLeft:'5px'}}><small>{LastActivities[la].member}</small></span>
                                <Button variant="primary" className={"pr-3 float-end"} style={{padding:'5px',background:'white',borderColor:'#23AAE1',color:'#23AAE1'}}>
                                    <span className="material-icons" style={{verticalAlign:'middle',marginLeft:'13px'}} onClick={()=>navigate('/task/single/'+LastActivities[la].project_id+'/'+LastActivities[la].task_id)}>
                                    logout
                                </span></Button> </h5>
                            <br style={{clear:'both'}}/>
                            <p className="text-muted mb-2 fw-bold">{LastActivities[la].regard}<br/><small>{LastActivities[la].created_at}</small></p>
                            <p className="text-muted" dangerouslySetInnerHTML={{__html: LastActivities[la].content}} style={{padding:'15px',borderRadius:'10px',border:'1px solid #E5E5E5'}} />
                        </li>

                    </>
                )
            }
        }
    }

    const deleteLabel = (id:any) => {

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
        };

        fetch(ApiRoot()+"yoptimize/delete/label/" +id+"/", requestOptions)
            .then(response => response.text())
            .then(result =>  setFetchData(Date.now()))
            .catch(error => console.log('error', error));
    }

    if (typeof response !== "boolean" && response?.length > 0) {





        for (let x = 0; x < response.length; x++) {
            if(response[x].support_label !== undefined && response[x].support_label !== null) {
                for (let sl = 0; sl < response[x].support_label.length; sl++) {

                    StackBadgeHolder.push(
                        <span className="badge rounded-pill"
                              style={{background: response[x].support_label[sl].color,fontSize:'16px'}}>{response[x].support_label[sl].name}
                            <span className="material-icons" style={{fontSize:'16px',verticalAlign:'bottom',cursor:'pointer'}} onClick={()=>deleteLabel(response[x].support_label[sl].id)}>
                                delete
                            </span>
                        </span>
                    )
                }
            }
            const send = (id:any) => {
                setCommentEdit(id)
                setFetchData(Date.now())
                AddedFunc(EditComment)

            }

            if(response[x].Comments !== undefined && response[x].Comments !== null) {
            let CommentsHolder = [];

            for (let r = response[x].Comments.length - 1; r > -1; r--) {
                CommentsHolder.push(response[x].Comments[r])
            }
            let Comments = CommentsHolder
            for (let c = 0; c < Comments.length; c++) {


                let kurzel = ''

                for (let k = 0; k < Comments[c].member.split(' ').length; k++) {
                    kurzel = kurzel + Comments[c].member.split(' ')[k].charAt(0).toLocaleUpperCase()
                }



                BuildComments.push(
                    <>
                        <Card className={'mb-5'}>
                            <Card.Header><Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"
                                                 className={'float-start'}>{kurzel}</Avatar>
                                <p className={'float-start'} style={{marginTop: '0.5rem', marginLeft: '1rem'}}>
                                    <small><strong>{Comments[c].member}</strong> </small>
                                    <small>Erstellt am: {Comments[c].created_at} </small></p>

                                <NavDropdown title={
                                    <>
                                    <span className="material-icons" style={{marginTop: '0.5rem'}}>
                                                settings
                                            </span>
                                    </>
                                } id="nav-dropdown" className={'float-end'}>
                                    <NavDropdown.Item onClick={(e) => send(c)}>Edit</NavDropdown.Item>
                                </NavDropdown>
                                <br style={{clear: 'both'}}/>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {Comments[c].comment !== null ?
                                        <div
                                            dangerouslySetInnerHTML={{__html: JSON.parse(JSON.stringify(Comments[c].comment.replaceAll(/\\\"/g, '"')))}}/>
                                        : <></>}
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer
                                className="text-muted"><small><strong>{Comments[c].member}</strong> Aktualisiert
                                am: {Comments[c].updatet_at}</small></Card.Footer>
                        </Card>
                    </>
                )
            }
        }
            if(response[x].attachment !== undefined && response[x].attachment !== null) {
                for(let a=0;a<response[x].attachment.length;a++){
                    let AttachHolder = [];
                    let type = response[x].attachment[a].type;
                    switch (true) {
                        case type.includes("image"):
                            AttachHolder.push(<Card.Img className={'img-thumbnail'} variant="top"
                                                        src={response[x].attachment[a].url} style={{
                                width: '250px',
                                height: '105px',
                                objectFit: 'contain'
                            }}/>)
                            break
                        case type.includes("pdf"):
                            AttachHolder.push(<>
                                <Card.Img className={'img-thumbnail'} variant="top"
                                          src="/assets/images/img.png" style={{
                                    width: '250px',
                                    height: '105px',
                                    objectFit: 'contain'
                                }}/>
                                </>
                                )
                            break
                        case type.includes("blob"):
                            AttachHolder.push(<>
                                    <Card.Img className={'img-thumbnail'} variant="top"
                                              src={response[x].attachment[a].url} style={{
                                        width: '250px',
                                        height: '105px',
                                        objectFit: 'contain'
                                    }}/>
                                </>
                            )
                            break
                        default:
                            AttachHolder.push(<>
                                <Card.Img className={'img-thumbnail'} variant="top"
                                          src="/assets/images/noimage.jpg" style={{
                                    width: '250px',
                                    height: '105px',
                                    objectFit: 'contain'
                                }}/>

                            </>)
                    }
                    BuildAttachtment.push(<>
                            <Card className={'float-start mr-3 mt-3'} style={{width: '250px'}}>
                                <Card.Header style={{height:'61px',overflow:'hidden'}}>
                                    <a target={'new'} href={response[x].attachment[a].url} title={response[x].attachment[a].name}
                                       style={{fontSize: '14.4px'}}>
                                        {response[x].attachment[a].name}
                                    </a>

                                </Card.Header>
                                <a target={'new'} href={response[x].attachment[a].url} title={response[x].attachment[a].name}
                                   style={{fontSize: '14.4px'}}>
                                {AttachHolder}
                                    </a>
                                <Card.Footer className="text-muted">
                                    Datei Type: {type} <br/>
                                    <small>{response[x].attachment[a].created_at} <br/>
                                       {response[x].attachment[a].member}</small>
                                </Card.Footer>
                            </Card>
                        </>
                    )
                }
            } if(response[x].external_Connections !== undefined && response[x].external_Connections !== null) {

                let externalConnectionsHolder = [];

                let ecData = response[x].external_Connections

                for(let ec = 0;ec<ecData.length;ec++) {
                    externalConnectionsHolder.push(
                        <>
                            <tr>
                                <th><span className="material-icons pr-1"
                                          style={{verticalAlign: 'middle'}}>
                                                settings
                                            </span>{ecData[ec].type}
                                </th>
                                <th><a target={'new'} href={ecData[ec].url as string}>{ecData[ec].title}</a></th>
                                <th>{ecData[ec].dependency}</th>
                                <th>{ecData[ec].creator}</th>
                                <th>{ecData[ec].created_at}</th>
                            </tr>
                        </>
                    )
                }


                BuildExternalConnections.push(<>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Type</th>
                            <th>Title</th>
                            <th>Dependency</th>
                            <th>Creator</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {externalConnectionsHolder}
                        </tbody>
                    </Table>
                </>)
            }

            if(response[x].internal_connections !== undefined && response[x].internal_connections !== null) {

                let internalConnectionsHolder = [];

                let ecData = response[x].internal_connections

                for(let ec = 0;ec<ecData.length;ec++) {
                    internalConnectionsHolder.push(
                        <>
                            <tr>
                                <th><span className="material-icons pr-1"
                                          style={{verticalAlign: 'middle'}}>
                                                settings
                                            </span>
                                    <Link className={''} to={'/task/single/'+ecData[ec].in_project+'/'+ecData[ec].url}>
                                        {ecData[ec].url} {ecData[ec].title}
                                    </Link>
                                </th>
                                <th>{ecData[ec].responsibility}</th>
                                <th>{ecData[ec].created_at}</th>
                            </tr>
                        </>
                    )
                }


                BuildInternalConnections.push(<>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>This task is linked to</th>
                            <th>Responsibility</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {internalConnectionsHolder}
                        </tbody>
                    </Table>
                </>)
            }

            if(response[x].support_subtask !== undefined && response[x].support_subtask !== null) {

                let SupportSubtaskHolder = [];

                let ecData = response[x].support_subtask

                for(let ec = 0;ec<ecData.length;ec++) {
                    SupportSubtaskHolder.push(
                        <>

                                <tr>
                                    <th><span className="material-icons pr-1 float-start"
                                              style={{verticalAlign: 'middle'}}>
                                                settings
                                            </span><Form.Check
                                        type={'checkbox'}
                                        label={ecData[ec].title}
                                        id={'todo'}
                                        defaultChecked={(ecData[ec].complete !== 0 ? true : false)}
                                        className={'float-start'}
                                    /></th>
                                    <th>{ecData[ec].responsibility}</th>
                                    <th>{ecData[ec].description}</th>
                                    <th>{ecData[ec].due_on.replaceAll('T',' ')}</th>
                                    <th>{ecData[ec].created_at}</th>
                                </tr>

                        </>
                    )
                }


                BuildSubTask.push(<>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Title</th>
                            <th>Responsibility</th>
                            <th>Description</th>
                            <th>Due to</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {SupportSubtaskHolder}
                        </tbody>
                    </Table>
                </>)
            }

        }



    }

    const collapse = (e:any,id:any) => {

        let arrow = document.getElementById(id+'_arrow') as HTMLSpanElement

        let Collapse = document.getElementById(id) as HTMLDivElement;
        if(Collapse.classList.contains("d-none") === true){
            Collapse.classList.remove("d-none");
            Collapse.classList.add("d-block");
            arrow.innerHTML = 'expand_less'
        } else {
            Collapse.classList.add("d-none");
            Collapse.classList.remove("d-block");
            arrow.innerHTML = 'expand_more'
        }

    }
    const AddedFunc = (form?:any,footer?:any,mode?:any,modeID?:any) => {



        if(mode !== undefined && mode !== null) {
            let Dialogs = document.getElementById((modeID !== undefined && modeID !== null && modeID !== '' ? modeID :'addDialogInternalConnection')) as HTMLDivElement;
            Dialogs.classList.add("show");
            Dialogs.style.display = 'block';
        } else {
            let Dialog = document.getElementById('addDialogMember') as HTMLDivElement;
            Dialog.classList.add("show");
            Dialog.style.display = 'block';
            if(form !== undefined) {
                let newElement = form
                let temp = document.createElement('div')
                ReactDOM.render(newElement, temp)
                let ele = document.getElementById('injectModalTask') as HTMLDivElement
                if(ele !== null) {
                    ele.innerHTML = ''
                    ele.prepend(temp)
                }
            }
        }




    }

    let EditComment = (<>

            <div className="modal-dialog">
                <Form
                    id={'form001'}
                    className={'formControl'}
                    noValidate
                    onSubmit={(e) =>
                        saveComment(e, CloseMember)
                    }
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4">Edit comment</div>
                            <button type="button" className="btn-close" aria-label="Close"
                                    onClick={() => CloseMember()}/>
                        </div>
                        <div className="modal-body" id={'FormBody'}>
                            <SunEditor
                                key={'text'}
                                name={'comment'}
                                /*defaultValue={Store.SingleTasks.comment[commentEdit].comment}*/
                                defaultValue={''}
                                setOptions={{
                                    buttonList: SunEditorConfig(),
                                    attributesWhitelist: {
                                        div: 'id',
                                        p: 'id',
                                        h: 'id'
                                    }
                                }}
                                height={'350px'}
                            />
                        </div><br/>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    onClick={() => CloseMember()}>Close
                            </button>
                            <button type="submit" className="btn btn-primary float-end">Edit comment</button>
                        </div>
                    </div>
                </Form>
            </div>


        </>)

    let Comment = (<>

            <div className="modal-dialog">
                <Form
                    id={'form001'}
                    className={'formControl'}
                    noValidate
                    onSubmit={(e) =>
                        saveComment(e,CloseMember)
                    }
                >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4">Add Comment</div>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => CloseMember()}/>
                    </div>
                    <div className="modal-body" id={'FormBody'}>
                        <SunEditor
                            key={'text'}
                            name={'comment'}
                            setOptions={{
                                buttonList: SunEditorConfig(),
                                attributesWhitelist: {
                                    div: 'id',
                                    p: 'id',
                                    h: 'id'
                                }
                            }}
                            height={'350px'}
                        />
                    </div><br/>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => CloseMember()}>Close
                        </button>
                        <button type="submit" className="btn btn-primary float-end">Add Comment</button>
                    </div>
                </div>
                </Form>
            </div>


                    </>)

    const saveComment = (e:any,close:any,divid?:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            comment: formdata.get('comment'),
            task_id: taskid,
            project_id:id,
            member: localStorage.getItem('username'),
            system:1
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/save/comment/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,close))
            .catch((error) => errorForm(error,close))

    }
    const successForm = (result:any,close:any,mode?:any,modeID?:any,rewrite?:any) => {
        setFetchData(Date.now())
        if(mode !== undefined && mode !== null) {
            if(modeID !== undefined && modeID !== null) {
                close(mode,modeID)
            }else{
                close(mode)
            }

        } else {
            close()
        }

        if(rewrite !== undefined && rewrite !== null) {
            navigate(result.url)
        }

    }

    const errorForm = (error:any,close:any,mode?:any,modeID?:any)=> {
        if(mode !== undefined && mode !== null) {
            if(modeID !== undefined && modeID !== null) {
                close(mode,modeID)
            }else{
                close(mode)
            }
        } else {
            close()
        }
    }

    let AttachDocument = (<>

        <div className="modal-dialog">
            <Form
                id={'form001'}
                className={'formControl'}
                noValidate
                onSubmit={(e) =>
                    sendAttachComment(e,CloseMember)
                }
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4">Attach document</div>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => CloseMember()}/>
                    </div>
                    <div className="modal-body" id={'FormBody'}>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label>Multiple files input</Form.Label>
                            <Form.Control id={'fileuploadAttachment'} name={'file'} type="file" multiple />
                        </Form.Group>
                        <input name={'url'}  type={'hidden'} defaultValue={''} id={'AttachDocumentUrl'} />
                        <input name={'name'} type={'hidden'} defaultValue={''} id={'AttachDocumentName'} />
                        <input name={'type'} type={'hidden'} defaultValue={''} id={'AttachDocumentType'} />
                    </div><br/>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => CloseMember()}>Close
                        </button>
                        <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" disabled={false}>Attach document</button>
                    </div>
                </div>
            </Form>
        </div>


    </>)



    let sendAttachComment = (e:any,close:any) => {
        e.preventDefault()
        let data = e.currentTarget



        let formfile = (document.getElementById('fileuploadAttachment') as HTMLInputElement).files

        if(formfile !== null) {
            for (let f = 0; f < formfile?.length; f++) {
                let formDatas = new FormData()
         formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
         formDatas.append('context', 'pielers_element_text_image')
         // @ts-ignore
         formDatas.append(`file${0}`, formfile[f])
         var requestOptions = {
             method: 'POST',
             body: formDatas
         }
         let link
         if (process.env.REACT_APP_MODE === 'LOCAL') {
             link = process.env.REACT_APP_CDN_API_URL_LOCAL
         } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
             link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
         } else if (process.env.REACT_APP_MODE === 'LIVE') {
             link = process.env.REACT_APP_CDN_API_URL_LIVE
         }

         fetch(link + 'upload/', requestOptions)
             .then((response) => response.text())
             .then((result) => {

                     var formdata = new FormData(data)
                     var raw = JSON.stringify({
                         url: JSON.parse(result).cdn_url,
                         name: JSON.parse(result).name,
                         task_id: taskid,
                         project_id:id,
                         member: localStorage.getItem('username'),
                         type:JSON.parse(result).content_type
                     })

                     var requestOptions = {
                         method: 'POST',
                         headers: {
                             'Content-Type': 'application/json',
                             Authorization:
                                 'Bearer ' +
                                 JSON.parse(localStorage.getItem('token') as string)
                         },
                         body: raw
                     }

                     fetch(ApiRoot()+'yoptimize/save/attachdocument/', requestOptions)
                         .then((response) => response.text())
                         .then((result) => successForm(result,close))
                         .catch((error) => errorForm(error,close))

             })
             .catch((e) => {
                 error(e)
             })
            }
        }
    }


    let addScreenshot = (<>

        <div className="modal-dialog">
            <Form
                id={'form001'}
                className={'formControl'}
                encType={"multipart/form-data"}
                noValidate
                onSubmit={(e) =>
                    sendScreenshot(e,CloseMember)
                }
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4">add screenshot</div>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => CloseMember()}/>
                    </div>
                    <div className="modal-body" id={'FormBody'} style={{height:'400px',overflow:"auto"}}>
                        <input name={'url'} type={'hidden'} id={'ScreenshotUrl'} defaultValue={''} />
                        <input name={'name'} type={'hidden'} id={'ScreenshotName'}  defaultValue={''}/>
                        <input name={'type'} type={'hidden'} id={'ScreenshotType'} defaultValue={''} />
                        <div className={'text-center'}><kbd className="key">Ctrl</kbd> + <kbd className="key">V</kbd> in this window.</div>
                        {/* @ts-ignore */}
                        <Gluejar onPaste={files => blobToFile(files[0],'Screenshot_aufgenommen_'+new Date().toDateString().toLocaleString().replace(',','').replaceAll(' ','_')+'.png')} errorHandler={err => console.error(err)}>
                            { /* @ts-ignore */
                                images =>
                                images.length > 0 &&
                                /* @ts-ignore */
                                images.map(image => <><img src={image} key={image} alt={`Pasted: ${image}`} style={{width:'100%'}} />


                                </>)
                            }
                        </Gluejar>

                    </div><br/>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => CloseMember()}>Close
                        </button>
                        <button type="submit" id={'ScreenshotSendButton'} className="btn btn-primary float-end" disabled={true}>add screenshot</button>
                    </div>
                </div>
            </Form>
        </div>


    </>)

        const blobToFile = (url:any, fileName:string) => {
            const reader = new FileReader();
            var requestOptions = {
                method: 'GET',
            };

            fetch(url, requestOptions)
                .then(response => response.blob())
                .then(result => {
                    let formDatas = new FormData()
                    formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
                    formDatas.append('context', 'pielers_element_text_image')
                    // @ts-ignore
                    formDatas.append(`file${0}`, result, fileName)
                    var requestOptions = {
                        method: 'POST',
                        body: formDatas
                    }

                    let link
                    if (process.env.REACT_APP_MODE === 'LOCAL') {
                        link = process.env.REACT_APP_CDN_API_URL_LOCAL
                    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                        link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
                    } else if (process.env.REACT_APP_MODE === 'LIVE') {
                        link = process.env.REACT_APP_CDN_API_URL_LIVE
                    }

                    //let storeimage:any;

                    fetch(link + 'upload/', requestOptions)
                        .then((response) => response.text())
                        .then((result) => {
                            notify_bucket(Store)
                                let eleSourceURL = document.getElementById('ScreenshotUrl') as HTMLInputElement
                                eleSourceURL.value = JSON.parse(result).cdn_url
                                let eleSourceName = document.getElementById('ScreenshotName') as HTMLInputElement
                                eleSourceName.value = JSON.parse(result).name
                                let eleSourceType = document.getElementById('ScreenshotType') as HTMLInputElement
                                eleSourceType.value = JSON.parse(result).content_type
                                let eleSourceSendButton = document.getElementById('ScreenshotSendButton') as HTMLInputElement
                                eleSourceSendButton.removeAttribute('disabled')

                        })
                        .catch((e) => {
                            error(e)
                        })
                })
                .catch(error => console.log('error', error));

    }

    let sendScreenshot = (e:any,close:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            url: formdata.get('url'),
            name: formdata.get('name'),
            task_id: taskid,
            project_id:id,
            member: localStorage.getItem('username'),
            type:formdata.get('type')
        })


        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/save/attachdocument/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,close))
            .catch((error) => errorForm(error,close))
    }

    let ExternalConnections = (<>

        <div className="modal-dialog">
            <Form
                id={'form001'}
                className={'formControl'}
                onSubmit={(e) =>
                    sendExternalConnections(e,CloseMember)
                }
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4">add external connections</div>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => CloseMember()}/>
                    </div>
                    <div className="modal-body" id={'FormBody'}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>URL</Form.Label>
                            <Form.Control name={'url'} type="text"  required={true}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Title</Form.Label>
                            <Form.Control name={'title'}  type="text" required={true}/>
                        </Form.Group>
                        <Form.Select name={'type'} aria-label="Default select example" required={true}>
                            <option>Select type</option>
                            <option value="Weblink">Weblink</option>
                        </Form.Select>
                    </div><br/>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => CloseMember()}>Close
                        </button>
                        <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end">add external connections</button>
                    </div>
                </div>
            </Form>
        </div>


    </>)
    let sendExternalConnections = (e:any,close:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            url: formdata.get('url'),
            title: formdata.get('title'),
            type:formdata.get('type'),
            task_id: taskid,
            project_id:id,
            dependency: 'Connected',
            creator: localStorage.getItem('username')
        })


        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/save/external_Connections/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,close))
            .catch((error) => errorForm(error,close))

    }



    let InternalConnections = () => {

        let FetchTaskHolder = [];

        if (FetchTask !== true && typeof FetchTask !== "boolean" && FetchTask?.length > 0) {
            for (let ft = 0; ft < FetchTask.length; ft++) {

                FetchTaskHolder.push(
                    <>
                        <option
                            value={FetchTask[ft].task_id + '|' + FetchTask[ft].regard + '|' + FetchTask[ft].project_id}>{FetchTask[ft].task_id}-{FetchTask[ft].regard}</option>
                    </>
                )
            }
        }



        let ProjectHolder = [];
        if (ALLProject !== true && typeof ALLProject !== "boolean" && ALLProject?.length > 0) {
            for (let ft = 0; ft < ALLProject.length; ft++) {

                ProjectHolder.push(
                    <>
                        <option
                            value={ALLProject[ft].id}>{ALLProject[ft].title}</option>
                    </>
                )
            }
        }


        return (<>
            <div className="offcanvas-body">
            <div className="modal-dialog">
                <Form
                    id={'form001'}
                    className={'formControl'}
                    onSubmit={(e) =>
                        sendInternalConnections(e, CloseMember,'addDialogInternalConnection')
                    }
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4">add internal connections</div>
                            <button type="button" className="btn-close" aria-label="Close"
                                    onClick={() => CloseMember(true,'addDialogInternalConnection')}/>
                        </div>
                        <div className="modal-body" id={'FormBody'}>
                            <Form.Select name={'InternalConnectionsInProject'} aria-label="Default select example" required={true}
                                         onChange={(e) => setFetchDataID(e.target.value)}>
                                <option label="Select project" value=""></option>
                                {ProjectHolder}
                            </Form.Select>
                            <br/>
                            <Form.Select name={'InternalConnectionsTaskId'} aria-label="Default select example" required={true}>
                                <option label="Select task" value=""></option>
                                {FetchTaskHolder}
                            </Form.Select>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Responsibility</Form.Label>
                                <Form.Select aria-label="Default select example" name={'InternalConnectionsResponsibility'}  required={true}>
                                    <option label="Responsibility select" value=""></option>
                                    <option value="assign a user">Do not assign a user</option>
                                    {/* @ts-ignore */
                                        UserHolder}
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <br/>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    onClick={() => CloseMember(true,'addDialogInternalConnection')}>Close
                            </button>
                            <button type="submit" id={'AttachDocumentSendButton'}
                                    className="btn btn-primary float-end">add internal connections
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
            </div>


        </>)
    }
    let sendInternalConnections = (e:any,close:any,Divid?:any) => {
        if (response !== true && typeof response !== "boolean" && response?.length > 0) {
            e.preventDefault()
            let data = e.currentTarget

            var formdata = new FormData(data)
            var raw = JSON.stringify({
                url: (formdata.get('InternalConnectionsTaskId') as string).split('|')[0],
                title: (formdata.get('InternalConnectionsTaskId') as string).split('|')[1],
                in_project: formdata.get('InternalConnectionsInProject'),
                task_id: taskid,
                project_id: id,
                responsibility: formdata.get('InternalConnectionsResponsibility'),
                member: localStorage.getItem('username')
            })

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

            fetch(ApiRoot() + 'yoptimize/save/internal_Connections/', requestOptions)
                .then((response) => response.text())
                .then((result) => successForm(result, close, true, Divid))
                .catch((error) => errorForm(error, close, true, Divid))

            var rawrevert = JSON.stringify({
                url: taskid,
                title: response[0].regard,
                task_id: (formdata.get('InternalConnectionsTaskId') as string).split('|')[0],
                in_project: id,
                project_id: formdata.get('InternalConnectionsInProject'),
                responsibility: formdata.get('InternalConnectionsResponsibility'),
                member: localStorage.getItem('username')
            })

            var requestOptionsrevert = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: rawrevert
            }

            fetch(ApiRoot() + 'yoptimize/save/internal_Connections/', requestOptionsrevert)
                .then((response) => response.text())
                .then((result) => successForm(result, close, true))
                .catch((error) => errorForm(error, close, true))
        }
    }

    const setterEditor = (id:any,id2:any) => {
        (document.getElementById(id) as HTMLDivElement).style.display = 'block';
        (document.getElementById(id2) as HTMLDivElement).style.display = 'none'
    }

    const setEditorContent = (value:any,textarea?:any) =>{
        setValue(value)
        if(textarea !== undefined) {
            (document.getElementById('descriptionTextarea') as HTMLTextAreaElement).value = value
        }
    }



     const EditTask =() => {
         if (response !== true && typeof response !== "boolean" && response?.length > 0) {
             let UserHolder = [];

             if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
                 for (let us = 0; us < AllUSer?.length; us++) {
                     UserHolder.push(
                         <>
                             <option value={AllUSer[us].username}>{AllUSer[us].username}</option>
                         </>
                     )
                 }
             }
            return(
            <> <div className="offcanvas-body">
                <div className="modal-dialog">
                    <div id="AgileTaskPriorities" className="table-list tooltip-agile-row" style={{display: 'none'}}>
                        <div className="table-list-header"><strong>Priorität der Aufgaben (in abfallender
                            Folge)</strong>

                        </div>
                        <div className="agile-row table-border-left agile-bg-red">
                            <div className="table-list-details agile-table-list-title">
                                <strong>P1</strong>
                            </div>
                            <div className="table-list-details agile-table-list-details">
                                <ul className="">
                                    <li className="">Oberste Priorität</li>
                                </ul>
                            </div>
                        </div>
                        <div className="agile-row table-border-left agile-bg-red">
                            <div className="table-list-details agile-table-list-title">
                                <strong>P2</strong>
                            </div>
                            <div className="table-list-details agile-table-list-details">
                                <ul className="">
                                    <li className="">Hohe Priorität</li>
                                </ul>
                            </div>
                        </div>
                        <div className="agile-row table-border-left agile-bg-green">
                            <div className="table-list-details agile-table-list-title">
                                <strong>P3</strong>
                            </div>
                            <div className="table-list-details agile-table-list-details">
                                <ul className="">
                                    <li className="">Laufende Arbeiten</li>
                                    <li className="">Fortlaufende Entwicklung</li>
                                </ul>
                            </div>
                        </div>
                        <div className="agile-row table-border-left agile-bg-green">
                            <div className="table-list-details agile-table-list-title">
                                <strong>P4</strong>
                            </div>
                            <div className="table-list-details agile-table-list-details">
                                <ul className="">
                                    <li className="">Geringe Priorität</li>
                                    <li className="">Kein Zeitplan</li>
                                </ul>
                            </div>
                        </div>
                        <div className="agile-row table-border-left agile-bg-cream">
                            <div className="table-list-details agile-table-list-title">
                                <strong>P5</strong>
                            </div>
                            <div className="table-list-details agile-table-list-details">
                                <ul className="">
                                    <li className="">Weniger wichtig</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Form
                        id={'form001'}
                        style={{padding: 0}}
                        className={'formControl'}
                        onSubmit={(e) =>
                            sendEditTask(e, CloseMember,'addDialogEditTask')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Edit task</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember(true,'addDialogEditTask')}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Title</InputGroup.Text>
                                    <Form.Control type="text" name={'title'} defaultValue={response[0].regard}
                                                  required={true}/>
                                </InputGroup>
                                <Button variant="outline-primary" onClick={()=>setterEditor('htmleditor','markdowneditor') }>HTML Editor</Button>
                                <Button variant="outline-primary" onClick={()=>setterEditor('markdowneditor','htmleditor') }>Markdown Editor</Button>

                                <div id={'htmleditor'} style={{display: 'block'}}>
                                    <SunEditor
                                        key={'test'}

                                        name={'description'}
                                        setContents={value}
                                        setOptions={{
                                            buttonList: SunEditorConfig(),
                                            attributesWhitelist: {
                                                div: 'id',
                                                p: 'id',
                                                h: 'id'
                                            }
                                        }}
                                        height={'373px'}
                                        onChange={(value) => setEditorContent(value)}
                                    />
                                </div>
                                <div id={'markdowneditor'} style={{display: 'none'}}>
                                    <MarkdownEditor
                                        value={value.replaceAll('<p>','').replaceAll('</p>','')}
                                        height={'450px'}
                                        visible={true}
                                        onChange={(value, viewUpdate) => setEditorContent(value,true)}

                                    />

                                </div>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Due on</Form.Label>
                                <Form.Control type="datetime-local" name={'due_on'}  />
                            </Form.Group>{/*
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Start date</Form.Label>
                                <Form.Control type="datetime-local" name={'Start_date'}  />
                            </Form.Group>*/}
                                <br/>
                                <Row>
                                    <Col className={'col-4'}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">Priority</InputGroup.Text>
                                            <Form.Select aria-label="Default select example" name={'priority'}
                                                         required={true} defaultValue={response[0].priority}>
                                                <option label="Priority select" value=""></option>
                                                <option value="0">P0</option>
                                                <option value="1">P1</option>
                                                <option value="2">P2</option>
                                                <option value="3">P3</option>
                                                <option value="4">P4</option>
                                                <option value="5">P5</option>
                                                <option value="6">P6</option>
                                            </Form.Select>
                                            <InputGroup.Text id="basic-addon1"><span
                                                className="material-icons float-end"
                                                onClick={() => switchdisplay('AgileTaskPriorities')}>
                                    info
                                    </span></InputGroup.Text>
                                        </InputGroup>

                                    </Col>
                                    <Col className={'col-6'}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">Responsibility</InputGroup.Text>
                                            <Form.Select aria-label="Default select example" name={'responsibility'}
                                                         required={true} defaultValue={response[0].responsibility}>
                                                <option label="Responsibility select" value=""></option>
                                                <option value="assign a user">Do not assign a user</option>
                                                {UserHolder}
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col className={'col-2'}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">Color</InputGroup.Text>
                                            <Form.Control
                                                type="color"
                                                name={'color'}
                                                defaultValue={response[0].color}
                                                id={'saveSwimelanecolor'}
                                            />
                                        </InputGroup>

                                    </Col>
                                </Row>
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember(true,'addDialogEditTask')}>Close
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'}
                                        className="btn btn-primary float-end">Save
                                </button>
                            </div>

                        </div>
                    </Form>
                </div>
            </div>
            </>
        )
    }

    }
    let sendEditTask = (e:any,close:any,divid?:any) => {
            e.preventDefault()
            let data = e.currentTarget

            var formdata = new FormData(data)
            var raw = JSON.stringify({
                regard: formdata.get('title'),
                description: formdata.get('description'),
                responsibility: formdata.get('responsibility'),
                priority: formdata.get('priority'),
                due_on: formdata.get('due_on'),
                lastupdatefrom: localStorage.getItem('username'),
                color:formdata.get('color')
            })

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

            fetch(ApiRoot()+'yoptimize/project/'+id+'/edit/task/'+taskid+'/', requestOptions)
                .then((response) => response.text())
                .then((result) => successForm(result,close,true,divid))
                .catch((error) => errorForm(error,close,divid))

        }

        let SubTask = (<>

            <div className="modal-dialog">
                <Form
                    id={'form001'}
                    className={'formControl'}
                    onSubmit={(e) =>
                        sendSubTask(e,CloseMember)
                    }
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4">add subtask</div>
                            <button type="button" className="btn-close" aria-label="Close"
                                    onClick={() => CloseMember()}/>
                        </div>
                        <div className="modal-body" id={'FormBody'}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Title</Form.Label>
                                <Form.Control name={'title'} type="text"  required={true}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={3} name={'description'} required={true}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Due to</Form.Label>
                                <Form.Control name={'due_to'} type="datetime-local" required={true}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Responsibility</Form.Label>
                                <Form.Select aria-label="Default select example" name={'responsibility'}  required={true}>
                                    <option label="Responsibility select" value=""></option>
                                    <option value="assign a user">Do not assign a user</option>
                                    {UserHolder}
                                </Form.Select>
                            </Form.Group>
                        </div><br/>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    onClick={() => CloseMember()}>Close
                            </button>
                            <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end">add subtask</button>
                        </div>
                    </div>
                </Form>
            </div>


        </>)
        let sendSubTask = (e:any,close:any) => {
            e.preventDefault()
            let data = e.currentTarget

            var formdata = new FormData(data)
            var raw = JSON.stringify({
                title: formdata.get('title'),
                description: formdata.get('description'),
                task_id: taskid,
                project_id:id,
                responsibility: formdata.get('responsibility'),
                due_on: formdata.get('due_to'),
                member: localStorage.getItem('username')
            })

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

           fetch(ApiRoot()+'yoptimize/save/subtask/', requestOptions)
                .then((response) => response.text())
                .then((result) => successForm(result,close))
                .catch((error) => errorForm(error,close))

        }

    const CloseMember = (mode?:any,modeID?:any) => {
        if(mode !== undefined && mode !== null) {
            let Dialogs = document.getElementById((modeID !== undefined && modeID !== null && modeID !== '' ? modeID :'addDialogInternalConnection')) as HTMLDivElement;
            Dialogs.classList.remove("d-none");
            Dialogs.style.display = 'none';
        } else {
            let Dialog = document.getElementById('addDialogMember') as HTMLDivElement;
            Dialog.classList.remove("d-none");
            Dialog.style.display = 'none';
        }

    }
        /* @ts-ignore */
        let ProjectHolder = [];
        if (ALLProject !== true && typeof ALLProject !== "boolean" && ALLProject?.length > 0) {
            for (let ft = 0; ft < ALLProject.length; ft++) {

                ProjectHolder.push(
                    <>
                        <option
                            value={ALLProject[ft].id}>{ALLProject[ft].title}</option>
                    </>
                )
            }
        }

    let Label = (<>

        <div className="modal-dialog">
            <Form
                id={'form001'}
                className={'formControl'}
                onSubmit={(e) =>
                    sendLabel(e,CloseMember)
                }
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4">add label</div>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => CloseMember()}/>
                    </div>
                    <div className="modal-body" id={'FormBody'}>
                        <Row>
                            <Col className={'col-10'}><Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Title</Form.Label>
                                <Form.Control name={'name'} type="text"  required={true}/>
                            </Form.Group></Col>
                            <Col className={'col-2'}><Form.Label htmlFor="exampleColorInput">Color</Form.Label>
                                <Form.Control
                                    required={true}
                                    type="color"
                                    name={'color'}
                                /></Col>
                        </Row>


                    </div><br/>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => CloseMember()}>Close
                        </button>
                        <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end">add label</button>
                    </div>
                </div>
            </Form>
        </div>


    </>)
    let sendLabel = (e:any,close:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            name: formdata.get('name'),
            color: formdata.get('color'),
            task_id: taskid,
            project_id:id,
            member: localStorage.getItem('username')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/save/label/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,close))
            .catch((error) => errorForm(error,close))

    }

    const DuplicateTask = () => {

            let SwimLaneHolder = [];
            let localSwimLanes = JSON.parse(localStorage.getItem('swimlanes') as string)

                for (let us = 0; us < localSwimLanes?.length; us++) {
                    SwimLaneHolder.push(
                        <>
                            <option value={localSwimLanes[us].id}>{localSwimLanes[us].name}</option>
                        </>
                    )
                }

        return (
            <>
                <div className="offcanvas-body">
                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        onSubmit={(e) =>
                            sendDuplicateTask(e, CloseMember,'addDialogDuplicateTask')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Duplicate Tasks</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember(true,'addDialogDuplicateTask')}/>
                            </div><br/>
                            <div className="modal-body" id={'FormBody'}>
                                <Form.Select name={'DuplicateTaskProjectID'} aria-label="Default select example"
                                             required={true} onChange={(e) => setSwim(e)}>
                                    <option label="Select project" value=""></option>
                                    { /* @ts-ignore */
                                        ProjectHolder}
                                </Form.Select>
                            </div><br/>
                            <div className="modal-body" id={'FormBody'}>
                                <Form.Select name={'DuplicateTaskSwimlaneID'} aria-label="Default select example"
                                             required={true}>
                                    <option label="Select Swimlane" value=""></option>
                                    {SwimLaneHolder}
                                </Form.Select>
                            </div><br/>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember(true,'addDialogDuplicateTask')}>Close
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'}
                                        className="btn btn-primary float-end">Duplicate Tasks
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
                </div>
            </>
        )
    }

        let sendDuplicateTask = (e:any,close:any,divid?:any) => {
            e.preventDefault()
            let data = e.currentTarget

            var formdata = new FormData(data)
            var raw = JSON.stringify({
                task_id: taskid,
                project_id:id
            })

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

            fetch(ApiRoot()+'yoptimize/copy/newproject/'+formdata.get('DuplicateTaskProjectID')+'/project/'+id+'/task/'+taskid+'/swimlane/'+formdata.get('DuplicateTaskSwimlaneID')+'/', requestOptions)
                .then((response) => response.text())
                .then((result) => successForm(result,close,true,divid))
                .catch((error) => errorForm(error,close,true,divid))

        }

        const MoveTask  = () => {

            let SwimLaneHolder = [];
            let localSwimLanes = JSON.parse(localStorage.getItem('swimlanes') as string)

                for (let us = 0; us < localSwimLanes?.length; us++) {
                    SwimLaneHolder.push(
                        <>
                            <option value={localSwimLanes[us].id}>{localSwimLanes[us].name}</option>
                        </>
                    )
                }



            return (
                <>
                    <div className="offcanvas-body">
                    <div className="modal-dialog">
                        <Form
                            id={'form001'}
                            className={'formControl'}
                            onSubmit={(e) =>
                                sendMoveTask(e, CloseMember,'addDialogMoveTask')
                            }
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-title h4">Move Task</div>
                                    <button type="button" className="btn-close" aria-label="Close"
                                            onClick={() => CloseMember(true,'addDialogMoveTask')}/>
                                </div><br/>
                                <div className="modal-body" id={'FormBody'}>
                                    <Form.Select name={'DuplicateTaskProjectID'} aria-label="Default select example"
                                                 required={true} onChange={(e) => setSwim(e)}>
                                        <option label="Select project" value=""></option>
                                        { /* @ts-ignore */
                                            ProjectHolder}
                                    </Form.Select>
                                </div><br/>
                                <div className="modal-body" id={'FormBody'}>
                                    <Form.Select name={'DuplicateTaskSwimlaneID'} aria-label="Default select example"
                                                 required={true}>
                                        <option label="Select Swimlane" value=""></option>
                                        {SwimLaneHolder}
                                    </Form.Select>
                                </div><br/>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary"
                                            onClick={() => CloseMember(true,'addDialogMoveTask')}>Close
                                    </button>
                                    <button type="submit" id={'AttachDocumentSendButton'}
                                            className="btn btn-primary float-end">Move Task
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    </div>
                </>
            )
        }
        let sendMoveTask = (e:any,close:any,divid?:any) => {
            e.preventDefault()
            let data = e.currentTarget

            var formdata = new FormData(data)
            var raw = JSON.stringify({
                task_id: taskid,
                project_id:id
            })

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

            fetch(ApiRoot()+'yoptimize/move/newproject/'+formdata.get('DuplicateTaskProjectID')+'/project/'+id+'/task/'+taskid+'/swimlane/'+formdata.get('DuplicateTaskSwimlaneID')+'/', requestOptions)
                .then((response) => response.json())
                .then((result) => successForm(result,close,true,divid,true))
                .catch((error) => errorForm(error,close,true,divid))

        }

    const CloseTask = () => {


        var raw = ''

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/project/'+id+'/close/task/'+taskid+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => msgAlert('Ticket erfolgreich geschlossen',result))
            .catch((error) => console.log('error', error))
    }

    const msgAlert = (msg:any,result:any) => {
        alert(msg)
    }

        const setResponsibilityTask = () => {


            var raw = JSON.stringify({
                responsibility: localStorage.getItem('username')
            })

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

            fetch(ApiRoot()+'yoptimize/project/'+id+'/task/'+taskid+'/responsibility/', requestOptions)
                .then((response) => response.text())
                .then((result) => setFetchData(Date.now()))
                .catch((error) => console.log('error', error))
        }




        useEffect(() => {
            let editorText = (document.getElementById('htmleditor') as HTMLDivElement)
            if(editorText !== null) {
               let text = (editorText.children[0] as HTMLTextAreaElement)
                if(text !== null) {
                    text.id='descriptionTextarea'
                }
            }
        })


    const switchState = (value:any) => {
        var raw = JSON.stringify(
            {
                task_id: taskid,
                project_id:id,
                status_id:value,
                lastupdatefrom: localStorage.getItem('username'),
            })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'state/byTask/yoptimize/', requestOptions)
            .then((response) => response.text())
            .then((result) => setFetchData(Date.now()))
            .catch((error) => console.log('error', error))
    }

    if (response !== true && typeof response !== "boolean" && response?.length > 0) {

        return (
            <>
                <div className={'ticketsystem ml-3'}>
                    {nosearch === true ? <Search navigate={navigate} /> : <></>}
                    <Row>
                        <Col xs={2} md={2} xl={2} xxl={2}>
                            <h3>  <Link className={'btn btn-primary'} to={'/mytask/new/projects/'+id+'/'+response[0].project.title} >
                    <span className="material-icons" style={{verticalAlign: 'middle', marginBottom: '7px'}}>
                        arrow_back_ios
                    </span>
                                Task {response[0].task_id}
                            </Link></h3></Col>
                        <Col><h3>{response[0].regard}  {refresh(setFetchData)}</h3></Col>
                    </Row>
                    <Row>

                        <Col xs={2} md={2} xl={2} xxl={2}>
                            <h4 onClick={(e) => collapse(e, 'summary')}>
                                Summary
                                <span id={'summary_arrow'} className="material-icons" style={{verticalAlign: 'bottom'}}>
                            expand_less
                        </span>
                            </h4>
                            <div id={'summary'} className={'d-block'}>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px'}} onClick={()=>switchdisplay('summaryAccordion',['Last_activitaet'])}>
                                 <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                summarize
                            </span>Summary</Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px'}} onClick={()=>switchdisplay('Last_activitaet',['summaryAccordion'])}>
                                 <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                palette
                            </span>Last activities</Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand href="#home" style={{fontSize: '16px'}}>
                                 <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                compare_arrows
                            </span>Transitions</Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand href="#home" style={{fontSize: '16px'}}>
                                 <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                equalizer
                            </span>Analysis</Navbar.Brand>
                                    </Container>
                                </Navbar>
                               {/* <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand href="#home" style={{fontSize: '16px'}}>
                                 <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                add_to_queue
                            </span>Metadata</Navbar.Brand>
                                    </Container>
                                </Navbar>*/}
                                <br/>
                            </div>
                            <h4 onClick={(e) => collapse(e, 'actions')}>Actions
                                <span id={'actions_arrow'} className="material-icons" style={{verticalAlign: 'bottom'}}>
                            expand_less
                        </span>
                            </h4>
                            <div id={'actions'} className={'d-block'}>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc('','',true,'addDialogEditTask')}>
                            <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                edit_note
                            </span>
                                            Edit task
                                        </Navbar.Brand>
                                    </Container>
                                </Navbar>
                               {/* <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc()}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                repeat
                            </span>
                                            Edit repeat</Navbar.Brand>
                                    </Container>
                                </Navbar>*/}
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc(Label)}>
                                  <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                new_label
                            </span>
                                            Create label</Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc(SubTask)}>
                                  <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                add
                            </span>
                                            Create subtask</Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc('','',true,'addDialogInternalConnection')}>
                                   <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                fork_right
                            </span>
                                            Add internal connection</Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc(ExternalConnections)}>
                                   <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                ios_share
                            </span>
                                            Add external connection</Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc(Comment)}>
                                   <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                chat
                            </span>
                                            Add comment</Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc(AttachDocument)}>
                                  <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                add_to_drive
                            </span>
                                            Attach document</Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc(addScreenshot)}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                photo_camera
                            </span>
                                            Add screenshot</Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc('','',true,'addDialogDuplicateTask')}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                content_copy
                            </span>Duplicate to another project</Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc('','',true,'addDialogMoveTask')}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                difference
                            </span>Move to another project</Navbar.Brand>
                                    </Container>
                                </Navbar>
                                {/*<Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc()}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                send
                            </span>Send via email</Navbar.Brand>
                                    </Container>
                                </Navbar>*/}
                               {/* <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc()}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                control_camera
                            </span>Move position</Navbar.Brand>
                                    </Container>
                                </Navbar>*/}
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => CloseTask()}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                delete
                            </span>Close task</Navbar.Brand>
                                    </Container>
                                </Navbar>
                            </div>
                        </Col>
                        <Col xs={10} md={10} xl={10} xxl={10}>
                            <Row>
                                <Col className={'col-6'}>
                                    <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col className={'col-3'}>Status<br/>
                                                    <Form.Select aria-label="Default select example"
                                                                 onChange={(e)=>switchState(e.target.value)}
                                                                 defaultValue={response[0].status.id as string}>
                                                        <option label={'Select State'}></option>
                                                        {StateHolder}
                                                    </Form.Select>
                                                </Col>
                                                <Col className={'col-3'}>Column<br/><strong>{response[0].column?.name}</strong></Col>
                                                <Col
                                                    className={'col-3'}>Swimlane<br/><strong>{response[0].swimlane.name}</strong></Col>
                                                <Col className={'col-3'}>Position<br/><strong>{response[0].position}</strong></Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col className={'col-3'}>Priority<br/><strong>P{response[0].priority}</strong></Col>
                                                <Col className={'col-3'}>Started<br/><strong>{response[0].started}</strong></Col>
                                                <Col className={'col-3'}>Creator<br/><strong>{response[0].creator}</strong></Col>
                                                <Col className={'col-3'}></Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className={'col-4'}>
                                    <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    Created<br/><strong>
                                                    {(response[0].created_at !== null ?

                                                        dateFormat(
                                                            response[0].created_at,
                                                            localStorage.getItem('locale'),
                                                            'us',
                                                            true,
                                                            false,
                                                            Store
                                                        ):
                                                        response[0].created_at)}
                                                </strong>
                                                </Col>
                                                <Col>
                                                    Due On<br/><strong>
                                                    {(response[0].due_on !== null ?
                                                        dateFormat(
                                                            response[0].created_at.replace('T',' '),
                                                            localStorage.getItem('locale'),
                                                            'us',
                                                            true,
                                                            false,
                                                            Store
                                                        ):
                                                        response[0].due_on)}
                                                </strong>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col>
                                                    Modified<br/><strong>
                                                    {(response[0].updated_at !== null ?
                                                            dateFormat(
                                                            response[0].updated_at.replace('T',' '),
                                                            localStorage.getItem('locale'),
                                                            'us',
                                                            true,
                                                                false,
                                                            Store
                                                        ) :
                                                    response[0].updated_at)}
                                                </strong>
                                                </Col>
                                                <Col>
                                                    Postponed<br/><strong>
                                                    {(response[0].postpaned_at !== null ?
                                                        dateFormat(
                                                            response[0].postpaned_at.replace('T',' '),
                                                            localStorage.getItem('locale'),
                                                            'us',
                                                            true,
                                                            false,
                                                            Store
                                                        ) :
                                                        response[0].postpaned_at)}
                                                </strong>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className={'col-2'}>
                                    <Card>
                                        <Card.Body>
                                            Responsibility
                                            <br/>
                                            <span className="material-icons"
                                                  style={{verticalAlign: 'middle', fontSize: '50px'}}>
                                    supervised_user_circle
                                    </span> {response[0].responsibility}
                                            <br/>
                                            <span className="material-icons pl-3" onClick={()=>setResponsibilityTask()}
                                                  style={{verticalAlign: 'middle', fontSize: '30px', color: '#2EB0D9'}}>
                                    person_add_alt
                                    </span> <span style={{color: '#2EB0D9',cursor:'pointer'}} onClick={()=>setResponsibilityTask()}>assign to me</span>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'pt-3'}>
                                <Stack direction="horizontal" gap={2}>
                                    {StackBadgeHolder}
                                </Stack>
                                </Col>
                            </Row>
                            <Row  id={'summaryAccordion'} className={'mt-3'} style={{display:'block'}}>
                                <Accordion className={'bg-transparent'} style={{background: 'none'}} alwaysOpen defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Description</Accordion.Header>
                                        <Accordion.Body >
                                            {/*<div dangerouslySetInnerHTML={{__html: simpleMarkdown(response[0].description)}} />*/}

                                            <MDEditor.Markdown
                                                source={response[0].description} />
                                            </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Subtask</Accordion.Header>
                                        <Accordion.Body>
                                            {BuildSubTask}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Internal connections</Accordion.Header>
                                        <Accordion.Body>
                                            {BuildInternalConnections}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>External connections</Accordion.Header>
                                        <Accordion.Body>
                                            {BuildExternalConnections}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Attachments</Accordion.Header>
                                        <Accordion.Body className={'pb-3'}>
                                            {BuildAttachtment}
                                            <br style={{clear: 'both'}}/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>Comments</Accordion.Header>
                                        <Accordion.Body>
                                            {BuildComments}
                                            <Button variant="primary" className={'float-end'} onClick={(e) => AddedFunc(Comment)}>New Comment</Button>
                                            <br style={{clear: 'both'}}/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Row>
                            <Row id={'Last_activitaet'} className={'mt-5'} style={{paddingLeft:'15px',paddingRight:'15px',display:'none'}}>
                                <Card>

                                        <Card.Body>
                                            <section >
                                            <ul className="timeline">
                                                {BuildLastActivities}
                                            </ul>
                                        </section>
                                        </Card.Body>

                                </Card>
                            </Row>
                        </Col>
                    </Row>


                </div>
                {/*<div role="dialog" aria-modal="true" className="fade modal" id={'addDialogMember'}>
                    <div id={'injectModalTask'}></div>
                </div>*/}

                <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                     id={'addDialogInternalConnection'} style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                    {InternalConnections()}
                </div>

                <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                     id={'addDialogDuplicateTask'} style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                    {DuplicateTask()}
                </div>
                <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                     id={'addDialogMoveTask'} style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                    {MoveTask()}
                </div>
                <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                     id={'addDialogEditTask'} style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                    {EditTask()}
                </div>
               {/* <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                     id={'addEditComment'} style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                    <div className="offcanvas-body">
                        {EditComment()}
                    </div>
                </div>*/}


                <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                     id="addDialogMember" style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>

                    <div className="offcanvas-body">
                        <div id="injectModalTask"></div>
                    </div>
                </div>


            </>
        )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }

}

export default SingleTask;


export const refresh = (setFetchData:any) => {

    return (
        <>
            <Button variant="secondary" className={'float-end ml-3'} style={{position:'relative',right:'5px',zIndex:'96'}} onClick={(e) => setFetchData(Date.now())}>
                <span className="material-icons" style={{verticalAlign:'bottom'}}>
                    autorenew
                </span>
                Reload Page
            </Button>
        </>

    )
}

export const switchdisplay = (id:any,closeAnother?:any,displayState?:any) => {

    if(closeAnother !==undefined && closeAnother !== null) {
        let ele = document.getElementById(id) as HTMLDivElement
        if(ele !== null) {
                ele.style.display = 'block'
        }
        for(let close=0;close < closeAnother.length;close++) {
            console.log(closeAnother[close])
            let eletmp = document.getElementById(closeAnother[close]) as HTMLDivElement
            console.log(eletmp)
            if(eletmp !== null) {
                eletmp.style.display = 'none'
            }
        }
    } else {
        let ele = document.getElementById(id) as HTMLDivElement
        if(ele !== null) {
            /*console.log(ele.style.display)*/
            if(ele.style.display === 'none') {
                ele.style.display = (displayState !== undefined && displayState !== null ? displayState : 'block')
            } else if(ele.style.display === (displayState !== undefined && displayState !== null ? displayState : 'block')) {
                ele.style.display = 'none'
            }
        }
    }



}


export const simpleMarkdown = (mdText:any) => {
    // first, handle syntax for code-block
    mdText = mdText.replaceAll(/\r\n/g, '\n')
    mdText = mdText.replaceAll(/\n~~~ *(.*?)\n([\s\S]*?)\n~~~/g, '<pre><code title="$1">$2</code></pre>' )
    mdText = mdText.replaceAll(/\n``` *(.*?)\n([\s\S]*?)\n```/g, '<pre><code title="$1">$2</code></pre>' )

    // split by "pre>", skip for code-block and process normal text
    var mdHTML = ''
    var mdCode = mdText.split( 'pre>')

    for (var i=0; i<mdCode.length; i++) {
        if ( mdCode[i].substr(-2) == '</' ) {
            mdHTML += '<pre>' + mdCode[i] + 'pre>'
        } else {
            mdHTML += mdCode[i].replace(/(.*)<$/, '$1')
                .replaceAll(/##### (.*?)\s*#*$/gm, '<h5>$1</h5>')
                .replaceAll(/#### (.*?)\s*#*$/gm, '<h4 id="$1">$1</h4>')
                .replaceAll(/### (.*?)\s*#*$/gm, '<h3 id="$1">$1</h3>')
                .replaceAll(/## (.*?)\s*#*$/gm, '<h2 id="$1">$1</h2>')
                .replaceAll(/# (.*?)\s*#*$/gm, '<h1 id="$1">$1</h1>')
                .replaceAll(/^-{3,}|^\_{3,}|^\*{3,}/gm, '<hr/>')
                .replaceAll(/``(.*?)``/gm, '<code>$1</code>' )
                .replaceAll(/`(.*?)`/gm, '<code>$1</code>' )
                .replaceAll(/^\>> (.*$)/gm, '<blockquote><blockquote>$1</blockquote></blockquote>')
                .replaceAll(/^\> (.*$)/gm, '<blockquote>$1</blockquote>')
                .replaceAll(/<\/blockquote\>\n<blockquote\>/g, '\n<br>' )
                .replaceAll(/<\/blockquote\>\n<br\><blockquote\>/g, '\n<br>' )
                .replaceAll(/!\[(.*?)\]\((.*?) "(.*?)"\)/gm, '<img alt="$1" src="$2" $3 />')
                .replaceAll(/!\[(.*?)\]\((.*?)\)/gm, '<img alt="$1" src="$2" />')
                .replaceAll(/\[(.*?)\]\((.*?) "(.*?)"\)/gm, '<a href="$2" title="$3">$1</a>')
                .replaceAll(/<http(.*?)\>/gm, '<a href="http$1">http$1</a>')
                .replaceAll(/\[(.*?)\]\(\)/gm, '<a href="$1">$1</a>')
                .replaceAll(/\[(.*?)\]\((.*?)\)/gm, '<a href="$2">$1</a>')
                .replaceAll(/^[\*|+|-][ |.](.*)/gm, '<ul><li>$1</li></ul>' ).replace(/<\/ul\>\n<ul\>/g, '\n' )
                .replaceAll(/^\d[ |.](.*)/gm, '<ol><li>$1</li></ol>' ).replace(/<\/ol\>\n<ol\>/g, '\n' )
                .replaceAll(/\*\*\*(.*)\*\*\*/gm, '<b><em>$1</em></b>')
                .replaceAll(/\*\*(.*)\*\*/gm, '<b>$1</b>')
                .replaceAll(/\*([\w \d]*)\*/gm, '<em>$1</em>')
                .replaceAll(/___(.*)___/gm, '<b><em>$1</em></b>')
                .replaceAll(/__(.*)__/gm, '<u>$1</u>')
                .replaceAll(/_([\w \d]*)_/gm, '<em>$1</em>')
                .replaceAll(/~~(.*)~~/gm, '<del>$1</del>')
                .replaceAll(/\^\^(.*)\^\^/gm, '<ins>$1</ins>')
                .replaceAll(/ +\n/g, '\n<br/>')
                .replaceAll(/\n\s*\n/g, '\n<p>\n')
                .replaceAll(/^ {4,10}(.*)/gm, '<pre><code>$1</code></pre>' )
                .replaceAll(/^\t(.*)/gm, '<pre><code>$1</code></pre>' )
                .replaceAll(/<\/code\><\/pre\>\n<pre\><code\>/g, '\n' )
                .replaceAll(/\\([`_\\\*\+\-\.\(\)\[\]\{\}])/gm, '$1' )
        }
    }

    return mdHTML.trim()
}
