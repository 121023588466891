import {useEffect, useState} from "react";
import {error} from "../../../../@WUM/core/component/const/error.services";
import {timetrackingApiType} from "../request/TimetrackingApi.interface";
import {activity_recording} from "../request/activity_recording";

export const ResponseAllTimes = (date:any,user:any,fetchData:any) => {
    const [posts, setPosts] = useState<timetrackingApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await activity_recording
                .getPosts(date,user)
                .then((data) => {
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [date,user,fetchData])

    return isError ? isError : posts
}
