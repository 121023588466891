import Table from "react-bootstrap/Table";
import React, {useState} from "react";
import {ResponsemyTasks} from "../../../API/response/ticket";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import {useNavigate} from "react-router-dom";

const MytaskUser = (value: any) => {
    const [OverdueHolderSortData, setOverdueHolderSortData] = useState([]);
    const [DuothisWeekHolderSortData, setDuothisWeekHolderSortData] = useState([]);
    const [DuelaterHolderSortData, setDuelaterHolderSortData] = useState([]);
    const [NoDueDateHolderSortData, setNoDueDateHolderSortData] = useState([]);
    const [fetchData, setFetchData] = useState(0);
    const response = ResponsemyTasks(localStorage.getItem('username')?.replaceAll(' ','_'),fetchData);
    //const response = ResponsemyTasks(localStorage.getItem('first_name')+'_'+localStorage.getItem('last_name'),fetchData);
    const navigate = useNavigate();
    const Configuration = {
        tableHeader: [
            'ID',
            'Task',
            'Duo Date',
            'Project',
            'State',
        ],
        tableHeaderType: ['string','string','string','string','string'],
        tableContent: ['task_id','regard','due_on','project.title','status_id']
    }

    let OverdueHolder = [];
    let DuothisWeekHolder = [];
    let DuelaterHolder = [];
    let NoDueDateHolder = [];



    if (response !== true && typeof response !== "boolean" && response !== undefined) {
        let DataoverDue = (OverdueHolderSortData.length === 0 ? response.alltoverduestasks : OverdueHolderSortData);
        for(let t=0;  t < DataoverDue.length;t++) {
            let data = DataoverDue[t]
            OverdueHolder.push(
                <tr onDoubleClick={()=> navigate('/task/single/'+data.project_id+'/'+data.task_id)}>
                    <td>{data.task_id}</td>
                    <td>{data.regard}</td>
                    <td>{data.due_on}</td>
                    <td>{data.project.title}</td>
                    <td>{data.status.state}</td>
                </tr>
            )
        }
        let DataDuothisWeek = (DuothisWeekHolderSortData.length === 0 ? response.alltLast7Ddaystasks : DuothisWeekHolderSortData);
        for(let t6=0; t6 < DataDuothisWeek.length;t6++) {
            let data = DataDuothisWeek[t6]
            DuothisWeekHolder.push(
                <tr onDoubleClick={()=> navigate('/task/single/'+data.project_id+'/'+data.task_id)}>
                    <td>{data.task_id}</td>
                    <td>{data.regard}</td>
                    <td>{data.due_on}</td>
                    <td>{data.project.title}</td>
                    <td>{data.status.state}</td>
                </tr>
            )
        }
        let DataDuelater = (DuelaterHolderSortData.length === 0 ? response.alltLastover7Ddaystasks : DuelaterHolderSortData);
        for(let t7=0; t7 < DataDuelater.length;t7++) {
            let data = DataDuelater[t7]
            DuelaterHolder.push(
                <tr onDoubleClick={()=> navigate('/task/single/'+data.project_id+'/'+data.task_id)}>
                    <td>{data.task_id}</td>
                    <td>{data.regard}</td>
                    <td>{data.due_on}</td>
                    <td>{data.project.title}</td>
                    <td>{data.status.state}</td>
                </tr>
            )
        }
            let Datas = (NoDueDateHolderSortData.length === 0 ? response.alltLastoverNoneDdaystasks : NoDueDateHolderSortData);
            for (let tn = 0; tn < Datas.length; tn++) {
                let data = Datas[tn]
                NoDueDateHolder.push(
                    <tr onDoubleClick={()=> navigate('/task/single/'+data.project_id+'/'+data.task_id)}>
                        <td>{data.task_id}</td>
                        <td>{data.regard}</td>
                        <td>{data.due_on}</td>
                        <td>{data.project.title}</td>
                        <td>{data.status.state}</td>
                    </tr>
                )
            }

    }

    let DataNone = response !== true && typeof response !== "boolean" && response !== undefined && NoDueDateHolderSortData.length === 0 ? response.alltLastoverNoneDdaystasks : NoDueDateHolderSortData
    let DataOverdue = response !== true && typeof response !== "boolean" && response !== undefined && OverdueHolderSortData.length === 0 ? response.alltoverduestasks : OverdueHolderSortData
    let DataDuothisWeek = response !== true && typeof response !== "boolean" && response !== undefined && DuothisWeekHolderSortData.length === 0 ? response.alltLast7Ddaystasks : DuothisWeekHolderSortData;
    let DataDuelater = response !== true && typeof response !== "boolean" && response !== undefined && DuelaterHolderSortData.length === 0 ? response.alltLastover7Ddaystasks : DuelaterHolderSortData;
    return (
        <>
            <div className={'mb-4'}>
                <div className="swimelane swimlanemyTask ml-3">
                    <p className={'m-0'}>
                        Overdue
                    </p>
                </div>
                <div className={'ml-3'}>
                    {OverdueHolder.length === 0 ? <><h3>No tasks available</h3></> :
                        <Table striped bordered hover>
                            <thead>
                            <TableHead Configuration={Configuration} setFetchData={setFetchData} data={DataOverdue} setSortData={setOverdueHolderSortData} />
                            </thead>
                            <tbody id={'TimeHolder'} className={'TimeHolder'}>
                            {OverdueHolder}
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
            <div  className={'mb-4'}>
                <div className="swimelane swimlanemyTask ml-3">
                    <p className={'m-0'}>
                        Due this week
                    </p>
                </div>
                <div className={'ml-3'}>
                    {DuothisWeekHolder.length === 0 ? <><h3>No tasks available</h3></> :
                        <Table striped bordered hover>
                            <thead>
                            <TableHead Configuration={Configuration} setFetchData={setFetchData} data={DataDuothisWeek} setSortData={setDuothisWeekHolderSortData} />
                            </thead>
                            <tbody id={'TimeHolder'} className={'TimeHolder'}>
                            {DuothisWeekHolder}
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
            <div  className={'mb-4'}>
                <div className="swimelane swimlanemyTask ml-3">
                    <p className={'m-0'}>
                        Due later
                    </p>
                </div>
                <div className={'ml-3'}>
                    {DuelaterHolder.length === 0 ? <><h3>No tasks available</h3></> :
                        <Table striped bordered hover>
                            <thead>
                            <TableHead Configuration={Configuration} setFetchData={setFetchData} data={DataDuelater} setSortData={setDuelaterHolderSortData} />
                            </thead>
                            <tbody id={'TimeHolder'} className={'TimeHolder'}>
                            {DuelaterHolder}
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
            <div  className={'mb-4'}>
                <div className="swimelane swimlanemyTask ml-3">
                    <p className={'m-0'}>
                        No due date
                    </p>
                </div>
                <div className={'ml-3'}>
                    {NoDueDateHolder.length === 0 ? <><h3>No tasks available</h3></> :
                        <Table striped bordered hover>
                            <thead>
                            <TableHead Configuration={Configuration} setFetchData={setFetchData} data={DataNone} setSortData={setNoDueDateHolderSortData} />
                            </thead>
                            <tbody id={'TimeHolder'} className={'TimeHolder'}>
                            {NoDueDateHolder}
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
        </>
    )
}

export default MytaskUser;


export const TableHead = (props:{data:any,setFetchData:any,Configuration:any,setSortData:any}) => {
    return(
        <>
            <tr>
                <th>
                    <TableSortIcons id={0} label={'ID'} setFetchData={props.setFetchData} DataNone={props.data} Configuration={props.Configuration} setSortData={props.setSortData} />
                </th>
                <th>
                    <TableSortIcons id={1} label={'Task'} setFetchData={props.setFetchData} DataNone={props.data} Configuration={props.Configuration} setSortData={props.setSortData} />
                </th>
                <th>  <TableSortIcons id={2} label={'Duo Date'} setFetchData={props.setFetchData} DataNone={props.data} Configuration={props.Configuration} setSortData={props.setSortData} />
                </th>
                <th>  <TableSortIcons id={3} label={'Project'} setFetchData={props.setFetchData} DataNone={props.data} Configuration={props.Configuration} setSortData={props.setSortData} />
                </th>
                <th> <TableSortIcons id={4} label={'State'} setFetchData={props.setFetchData} DataNone={props.data} Configuration={props.Configuration} setSortData={props.setSortData} />
                </th>
            </tr>
        </>

    )

}

export const TableSortIcons = (props:{label:any,id:any,DataNone:any,Configuration:any,setSortData:any,setFetchData:any}) => {

    return(
        <>
            {props.label}
        <span className="material-icons" style={{verticalAlign:'middle'}}
              onClick={()=>Sort(props.DataNone,'asc',props.Configuration.tableHeaderType[props.id],props.Configuration.tableContent[props.id],props.setSortData,props.setFetchData)}>
                    expand_less
                    </span>
            <span className="material-icons" style={{verticalAlign:'middle'}}
                  onClick={()=>Sort(props.DataNone,'desc',props.Configuration.tableHeaderType[props.id],props.Configuration.tableContent[props.id],props.setSortData,props.setFetchData)}>
                    expand_more
                    </span>
        </>
    )
}

const Sort = (Datas:any,sortmode:any,sorttype:any,part:any,setter:any,responseSetter:any) => {
    let Sortback;
    let Data =  Datas
    if(sortmode === 'asc') {
        Sortback = Data.sort(function(a:any, b:any) {
            switch (sorttype) {
                case 'string':
                    if(part.includes('.') === true) {
                        return (a[part.split('.')[0]][part.split('.')[1]] > b[part.split('.')[0]][part.split('.')[1]]) ? 1 : ((a[part.split('.')[0]][part.split('.')[1]] < b[part.split('.')[0]][part.split('.')[1]]) ? -1 : 0)
                    } else {
                        return (a[part] > b[part]) ? 1 : ((a[part] < b[part]) ? -1 : 0)
                    }

                case 'date':
                    if(part.includes('.') === true) {
                        return new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime()
                    } else {
                        return new Date(a[part]).getTime() - new Date(b[part]).getTime()
                    }

                case 'integer':
                    if(part.includes('.') === true) {
                        return  a[part.split('.')[0]][part.split('.')[1]] - b[part.split('.')[0]][part.split('.')[1]]
                    } else {
                        return  a[part] - b[part]
                    }

            }
        });
    } else if(sortmode === 'desc') {
        Sortback = Data.sort(function(a:any, b:any) {
            switch (sorttype) {
                case 'string':
                    if(part.includes('.') === true) {
                        return (b[part.split('.')[0]][part.split('.')[1]] > a[part.split('.')[0]][part.split('.')[1]]) ? 1 : ((b[part.split('.')[0]][part.split('.')[1]] < a[part.split('.')[0]][part.split('.')[1]]) ? -1 : 0)
                    } else {
                        return (b[part] > a[part]) ? 1 : ((b[part] < a[part]) ? -1 : 0)
                    }

                case 'date':
                    if(part.includes('.') === true) {
                        return new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime()
                    } else {
                        return new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime()
                    }

                case 'integer':
                    if(part.includes('.') === true) {
                        return  b[part.split('.')[0]][part.split('.')[1]] - a[part.split('.')[0]][part.split('.')[1]]
                    }else{
                        return  b[part] - a[part]
                    }

            }
        });
    }
    setter(Sortback)
    responseSetter(Date.now())
}
