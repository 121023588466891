import {ResponseReports} from "../../../API/response/ticket";
import React, {useState} from "react";
import { Chart } from "react-google-charts";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from "react-bootstrap/Card";
import NavDropdown from "react-bootstrap/NavDropdown";
import {between2digits, savearchivProject, savedeleteProject} from "./MyProjects";
import ProgressBar from "react-bootstrap/ProgressBar";
const Reports = (value:any) => {
    const [fetchData, setFetchData] = useState(0);
    let heute = new Date()
    let day = heute.getDate().toString().padStart(2, '0');
    let month = (heute.getMonth()+1).toString().padStart(2, '0');
    let year = heute.getFullYear().toString().padStart(2, '0');
    let DBdate = year+'-'+month+'-01';
    let DBdateend = year+'-'+month+'-30';
    const [startDateData, setStartDate] = useState(DBdate);
    const [enddateData, setEndDate] = useState(DBdateend);
    const Report = ResponseReports(startDateData,enddateData,fetchData);


    const newDate = (date:any,setter:any) => {
        setter(date)
        setFetchData(Date.now())
    }


    if (typeof Report !== "boolean" && Report !== undefined ) {
            const data = [
                ["Task", "Hours per Day"],
                ["OpenTask", Report.TaskOverview.OpenTask],
                ["CompletedTask", Report.TaskOverview.CompletedTask],
                ["Overdue", Report.TaskOverview.Overdue], // CSS-style declaration
            ];


        const options = {
            pieHole: 0.5,
            is3D: false,
            width:400,
            height:300,
            slices:{0:{color: 'F1AF04'},1:{color: '10B82B'},2:{color: 'CD0000'}},
            legend: {position: 'none'}
        };
        return(
            <>
                <Row className={'m-3'}>
                    <Row>
                        <Col className={'col-3'}>
                            <h3>TASK OVERVIEW</h3>
                        </Col>
                        <Col className={'col-3'}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">Start</InputGroup.Text>
                                <Form.Control type="date" defaultValue={startDateData} onChange={(e) => newDate(e.target.value,setStartDate) }/>
                            </InputGroup>
                        </Col>
                        <Col className={'col-3'}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">End</InputGroup.Text>
                                <Form.Control type="date" defaultValue={enddateData} onChange={(e) => newDate(e.target.value,setEndDate) }/>
                            </InputGroup>
                        </Col>
                        <Col className={'col-3'}>
                        </Col>


                    </Row>
                    <Row>
                        <Col className={'col-3'}>
                            <BuildCard all={Report.TaskOverview.All} title={'Open Task'} dataItem={Report.TaskOverview.OpenTask} endDate={enddateData} startDate={startDateData} undertitle={'Open'} />
                        </Col>
                        <Col className={'col-3'}>
                            <BuildCard all={Report.TaskOverview.All} title={'Overdue Task'} dataItem={Report.TaskOverview.Overdue} endDate={enddateData} startDate={startDateData} undertitle={'Open'} />
                        </Col>
                        <Col className={'col-3'}>
                            <BuildCard all={Report.TaskOverview.All} title={'Completed Task'} dataItem={Report.TaskOverview.CompletedTask} endDate={enddateData} startDate={startDateData} undertitle={'Open'} />
                        </Col>
                        <Col className={'col-3'}>
                            <Chart
                                chartType="PieChart"
                                data={data}
                                options={options}
                                style={{marginTop: '-90px'}}
                            />
                        </Col>
                    </Row>

                </Row>

            </>
        )
    } else {
        return(
            <>

            </>
        )
    }

}

export default Reports;


const BuildCard = (props:{title:string,startDate:any,endDate:any,undertitle:any,dataItem:any,all:any}) => {



    return(
        <>
            <Card style={{ width: '100%',marginBottom:'10px',cursor:'move'}}>
                <Card.Body className={'pb-0'}>
                    <Card.Title>
                        <Row>
                            <Col className={'col-9'}><small>
                                <p  style={{marginBottom: '0'}}><strong>{props.title}</strong>
                                    <br/>
                                    <span style={{fontSize:'12px'}}>{props.startDate} - {props.endDate}</span>
                                </p>
                            </small>
                            </Col>
                           {/* <Col className={'col-3'}><NavDropdown
                                className={'float-end text-right'}
                                title={
                                    <span>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#999999"
                                            className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                      <path
                                          d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                 </span>
                                }
                                id='collasible-nav-dropdown_dashboard_projects'>
                                <NavDropdown.Item >Edit</NavDropdown.Item>
                            </NavDropdown> </Col>*/}
                        </Row>
                        <small style={{fontSize:'small'}}></small>
                    </Card.Title>
                    <Card.Text>
                        <ProgressBar style={{height:'5px'}} variant={between2digits((100 / (props.all as number) ) * (props.dataItem as number))} now={(100 / (props.all as number) ) * (props.dataItem as number)} />
                        <div id={'cardITem'} className={'mt-3'}>
                            <Row>
                                <Col md={8} xxl={8} xl={8} sm={8}>
                                    <div className={'border-'+between2digits((100 / (props.all as number) ) * (props.dataItem as number))}
                                         style={{borderInlineStart:'solid',writingMode:'horizontal-tb',paddingLeft:'5px'}}>{props.undertitle}<br/>
                                        {props.dataItem} Task</div>
                                </Col>
                                <Col  md={4} xxl={4} xl={4} sm={4}><p style={{textAlign:'right'}}><br/>
                                    <p className={'float-end'} style={{textAlign: 'right',color:"red"}}>{(100 / (props.all as number) ) * (props.dataItem as number)}%</p>
                                </p></Col>
                            </Row>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}
