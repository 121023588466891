import PielersForm from "../index";
import ReactDOM from "react-dom";

const MultiAdded  = (data: any, i: any, Store: any) => {



    return(
        <>
            <span className="material-icons float-end pointer" style={{color:data.color}} onClick={()=>
                addedContent(data.injecktelement,data.addedfunc,data.addedvalue,i)}>
            {data.icon}
            </span>
            {PielersForm(
                JSON.parse(JSON.stringify(data.injecktelement).replaceAll('[added]','[0]').replaceAll('[header]','[1]'))
            )}
        <div id={'MultiContent_'+i}/>
        </>
    )


}

export default MultiAdded;



const addedContent = (injecktelement:any,setx:any,x:any,i:any) => {
    let newElement =  <>{PielersForm(
        JSON.parse(JSON.stringify(injecktelement).replaceAll('[added]','['+x+']').replaceAll('[header]','['+(x+1)+']'))
)}</>
    let temp = document.createElement('div')

    ReactDOM.render(newElement, temp)
    let ele = document.getElementById('MultiContent_'+i)
    if (ele !== null) {
        ele.appendChild(temp)
        setx(x+1)
    }

}

