import { logout } from '../service/AuthService'
import {useLocation, useNavigate} from "react-router-dom";
import {notify_logout, notify_token_exp} from "../../../../core/component/const/notification.service";
import Alert from 'react-bootstrap/Alert';

const Logout = (value: any) => {
    let { state } = useLocation();
    const navigate = useNavigate();

    let alert = <></>;

    if(state !== undefined && state !== null && state.reload === true) {

        alert = <>
            <Alert variant="warning" className={'animateTop notifiLogout'}  dismissible>
                <Alert.Heading>JWT Token expire</Alert.Heading>
                <p>
                    Sie sind nicht mehr angemeldet, da ihr Token abgelaufen ist.
                </p>
            </Alert>
        </>
        state.reload = false
        setTimeout(
            () => window.location.reload(),
            3000
        )

    } else {
        alert = <>
            <Alert variant="success" className={'animateTop notifiLogout'}  dismissible>
                <Alert.Heading>Logout</Alert.Heading>
                <p>
                    Erfolgreich ausgeloggt. Sie werden zum Login weitergeleitet
                </p>
            </Alert>
        </>
        logout(navigate)
        setTimeout(
            () => window.location.href = '/Login',
            3000
        )

    }



    return <>
        {alert}
    </>
}

export default Logout
