
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React, {useState,useEffect} from 'react'
import {CreateTaskCard} from "../components/CreateProjectCard";
import appendIcon from "../components/appendIcon";
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import DynamikModal, {DynamikOffCanvas} from "../../../../../@WUM/core/component/const/DynamikModal";
import {useSelector} from "react-redux";
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
import {getToken} from "../../../../../@WUM/core/component/const/getToken";
import PielersForm from '../../../../../@WUM/core/form-serilizer/index'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    ResponseAllLastActivities, ResponseAllLastActivitiesProject,
    ResponseAllTask,
    ResponseAllTaskColumn,
    ResponseAllTaskSwimelanes,
    ResponseAllTResponsibility, ResponseSingleProject
} from "../../../API/response/ticket";
import SunEditorConfig from "../../components/SuneditorConfig";
import SunEditorBig from "../../../../../@WUM/core/component/const/SunEditorBig";
import {BoardTableSwitcher, FilterResponse, Search, TableMakerSupport} from "./MyProjects";
import {refresh, switchdisplay} from "./SingleTask";
import InputGroup from "react-bootstrap/InputGroup";
import '../css/timeline.css'
import Sortable from 'sortablejs'
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import ReactDOM from "react-dom";
import Avatar from "@material-ui/core/Avatar";
import Card from 'react-bootstrap/Card';
import MDEditor from "@uiw/react-md-editor";
import MarkdownEditor from '@uiw/react-markdown-editor';
import SunEditor from "suneditor-react";
const MyTask = (props:{value:any}) => {
    const { id,name } = useParams<{ id: string,name:string }>()
    let ProjectId = id;
    const [fetchData, setFetchData] = useState(0);
    const [archiv, setArchiv] = useState(0);
    const [del, setDel] = useState(0);
    let response = ResponseAllTask(id,fetchData,archiv,del)
    let Column = ResponseAllTaskColumn(fetchData)
    let Swimelanes = ResponseAllTaskSwimelanes(id,fetchData)
    let AllUSer = ResponseAllTResponsibility(fetchData)
    let UserHolder = [];
    const singleProject = ResponseSingleProject(ProjectId);
    const [value, setValue] = useState("");
    const navigate = useNavigate();
    const LastActivities = ResponseAllLastActivitiesProject(id,fetchData)
    let BuildLastActivities = [];
    if (typeof LastActivities !== "boolean" && LastActivities?.length > 0) {
        for (let la = 0; la < LastActivities.length; la++) {

            if(LastActivities[la].system === '9') {
                BuildLastActivities.push(
                    <>
                        <li className="timeline-item mb-5" style={{padding:'10px'}}>
                            <h5 className="fw-bold"><Avatar className={'float-start'} alt={LastActivities[la].member} src="/static/images/avatar/1.jpg" />
                                <span className={'float-start'} style={{paddingTop:'5px',paddingLeft:'5px'}}><small>{LastActivities[la].member}</small></span>
                                <span className={'float-end pr-3 btn btn-info'} style={{cursor:'default',background:'#23AAE1',color:'white'}}><small><strong>Extern</strong></small></span>
                                <Button variant="primary" className={"pr-3 float-end"} style={{padding:'5px',background:'white',borderColor:'#23AAE1',color:'#23AAE1'}}>
                                    <span className="material-icons" style={{verticalAlign:'middle',marginLeft:'13px'}} onClick={()=>navigate('/task/single/'+LastActivities[la].project_id+'/'+LastActivities[la].task_id)}>
                                    logout
                                </span></Button>
                            </h5>
                            <br style={{clear:'both'}}/>
                            <p className="text-muted mb-2 fw-bold">{LastActivities[la].regard}<br/><small>{LastActivities[la].created_at}</small></p>
                            <p className="text-muted" dangerouslySetInnerHTML={{__html: LastActivities[la].content}} style={{padding:'15px',borderRadius:'10px',border:'1px solid #E5E5E5'}} />
                        </li>

                    </>
                )
            } else {
                BuildLastActivities.push(
                    <>
                        <li className="timeline-item mb-5">
                            <h5 className="fw-bold"><Avatar className={'float-start'} alt={LastActivities[la].member} src="/static/images/avatar/1.jpg">
                                MU
                            </Avatar><span className={'float-start'} style={{paddingTop:'5px',paddingLeft:'5px'}}><small>{LastActivities[la].member}</small></span>
                                <Button variant="primary" className={"pr-3 float-end"} style={{padding:'5px',background:'white',borderColor:'#23AAE1',color:'#23AAE1'}}>
                                    <span className="material-icons" style={{verticalAlign:'middle',marginLeft:'13px'}} onClick={()=>navigate('/task/single/'+LastActivities[la].project_id+'/'+LastActivities[la].task_id)}>
                                    logout
                                </span></Button> </h5>
                            <br style={{clear:'both'}}/>
                            <p className="text-muted mb-2 fw-bold">{LastActivities[la].regard}<br/><small>{LastActivities[la].created_at}</small></p>
                            <p className="text-muted" dangerouslySetInnerHTML={{__html: LastActivities[la].content}} style={{padding:'15px',borderRadius:'10px',border:'1px solid #E5E5E5'}} />
                        </li>

                    </>
                )
            }
        }
    }

    if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
        for (let us = 0; us < AllUSer?.length; us++) {
            UserHolder.push(
                <>
                    <option value={AllUSer[us].username}>{AllUSer[us].username}</option>
                </>
            )
        }
    }

    const [fetchSortData, setFetchSortData] = useState([]);
    const Store = useSelector((state: any) => state)
    const [filter, setfilter] = useState([]);
    let y = 1;
    /*let buildCreateTaskCardOpen = [];
    let buildCreateTaskCardInProgress = [];
    let buildCreateTaskCardInRewview = [];
    let buildCreateTaskCardCompleted = [];*/

    let buildSwimelanes = [];



    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectcolumn, setSelectcolumn] = useState(0);
    const [selectswimelane, setswimelane] = useState(0);
    let  firstswimelane;
    const CloseDynmaikModal = () => {
        handleClose();
        setSelectcolumn(0)
        setswimelane(0)
    }

    const openNew = (columnID:any,SwimlaneID:any) => {
        handleShow()
        setSelectcolumn(columnID)
        setswimelane(SwimlaneID)
    }

    const EditSwimelane = (id:any,color:any,name:any) => {
        return(<>

        <div className="modal-dialog">
            <Form
                id={'form001'}
                className={'formControl'}
                noValidate
                onSubmit={(e) =>
                    editSwimelane(e,CloseMember,'addDialogdynamik')
                }
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4">Edit swimlane</div>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}/>
                    </div>
                    <div className="modal-body" id={'FormBody'}>
                        <Row>
                            <Col className={'col-10'}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" id={'editSwimelanename'} name={'name'} required={true} defaultValue={name}/>
                            </Form.Group></Col>
                            <Col className={'col-2'}> <Form.Label htmlFor="exampleColorInput">Color</Form.Label>
                                <Form.Control
                                    type="color"
                                    name={'color'}
                                    id={'editSwimelanecolor'}
                                    defaultValue={color}
                                /></Col>
                        </Row>
                        <input name={'id'} type="hidden" id="id" className="form-control" defaultValue={id} />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}>Close
                        </button>
                        <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >Edit swimlane</button>
                    </div>
                </div>
            </Form>
        </div>
            </>
    )}
    const editSwimelane = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            name: formdata.get('name'),
            color: formdata.get('color')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/edit/swimelanes/'+formdata.get('id')+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successFormswimelane(result,CloseMember,CloseMemberID,[{id:'saveSwimelanename',value:''},{id:'saveSwimelanecolor',value:'#10B82B'}]))
            .catch((error) => errorFormswimelane(error,CloseMember,CloseMemberID))
    }
    if(Swimelanes !== true && typeof Swimelanes !== "boolean" && Swimelanes?.length > 0) {


        let Swimelane = [];
        firstswimelane = Swimelanes[0].id
        for(let sw = 0; sw < Swimelanes.length; sw++) {
            let TMPbuildCreateTaskCard = [];
            let SwimelaneCounter = 0;
            let Swimelaneid = Swimelanes[sw].id;
            let background = Swimelanes[sw].color



            if (Column !== true && typeof Column !== "boolean" && Column?.length > 0
            ) {
                let TMPCreateTaskCard = [];

                for (let c = 0; c < Column.length; c++) {
                    let buildCreateTask = [];
                    let counter = 0;

                    if (response !== true && typeof response !== "boolean" && response?.length > 0
                    ) {
                        let Data = (fetchSortData.length === 0 ? response : fetchSortData);
                        for (let x = 0; x < Data.length; x++) {

                            if (Data[x].column.id === Column[c].id && Swimelanes[sw].id == Data[x].swimlane_id) {
                                counter++
                                SwimelaneCounter = SwimelaneCounter + 1
                                buildCreateTask.push(<>
                                    <CreateTaskCard Store={Store} ProjectId={Data[x].project_id}  swimelane={Swimelanes[sw].id} priorität={Data[x].priority}
                                                    position={(Data[x].position !== null ? Data[x].position : x)} comment={Data[x].comment}
                                                    id={Data[x].task_id} avatarID={1} state={(Data[x].status !== undefined && Data[x].status !== null ? Data[x].status.state : 0)}
                                                    title={Data[x].regard} now={100} variant={'success'}
                                                    status={'Open'} daysColor={'248, 98, 27'} daysLeft={2} task={1}
                                                    allTask={1} archiv={Data[x].archiv} setFetchData={setFetchData}
                                                    label={Data[x].support_label} delete={Data[x].delete}
                                                    teamMember={true} date={Data[x].created_at} text={Data[x].instanz}
                                                    background={(Data[x].instanz !== undefined && Data[x].instanz !== null && Data[x].instanz !== '' ? '#e5f1fd' : (Data[x].color !== undefined && Data[x].color !== null ? Data[x].color : 'white'))}/>
                                </>)
                            }
                        }
                    }

                    let cid = Column[c].id;

                    TMPCreateTaskCard.push(
                        <>
                            <Col>
                                <h6 className={'text-uppercase'} style={{
                                    borderBottom: '2px solid #F8621B',
                                    paddingBottom: '15px'
                                }}>{Column[c].name} ({counter})</h6>
                                <div className={'nested-sortable'} data-columnID={cid}  data-swimelane={Swimelanes[sw].id}
                                     style={{minHeight: (buildCreateTask.length > 0 ? 'auto' : '60px')}}>
                                    {buildCreateTask}
                                </div>
                                <br/>
                                <p onClick={() => openNew(cid,Swimelaneid)} className={'text-center'} style={{cursor:'pointer'}}>
                            <span  className="material-icons" style={{verticalAlign: 'bottom'}}>
                                add
                            </span>
                                    Add new task
                                </p>
                            </Col>
                        </>
                    )

                }
                TMPbuildCreateTaskCard.push(TMPCreateTaskCard)
            }


            const setterSwimelane = (id:any,closeAnother?:any,displayState?:any) => {
                switchdisplay(id,closeAnother,displayState)
                let swimelane = document.getElementById('swimelane_'+Swimelaneid) as HTMLDivElement;
                if(swimelane !== null) {
                    if (swimelane.style.display === 'none') {
                        let Icon = document.getElementById('expand_swimelane_' + Swimelaneid) as HTMLSpanElement;
                        if (Icon !== null) {
                            Icon.innerHTML = 'expand_more'
                        }
                    } else {
                        let Icon = document.getElementById('expand_swimelane_' + Swimelaneid) as HTMLSpanElement;
                        if (Icon !== null) {
                            Icon.innerHTML = 'expand_less'
                        }
                    }
                }
            }
            let swimlanename = Swimelanes[sw].name
            Swimelane.push(
                <>
                    <div className={'swimelane'} style={{background: background,borderRadius:'0px',marginLeft:'10px',width:'97.4%'}}>
                            <p  >
                                 <span className="material-icons float-start" style={{cursor:'pointer'}} id={'expand_swimelane_'+Swimelaneid} onClick={()=>setterSwimelane('swimelane_'+Swimelaneid,null,'flex')}>
                                    expand_less
                                </span>
                            <span className={'ml-1 float-start'} style={{cursor:'pointer'}} onClick={()=>setterSwimelane('swimelane_'+Swimelaneid,null,'flex')}>{swimlanename} ({SwimelaneCounter})</span>

                                <Button variant="primary" className={'btn-sm float-end'} style={{height:'20px',width:'20px',padding:'0px',marginTop:'2px'}}>
                            <span className="material-icons " style={{fontSize:'18px'}}
                                  onClick={(e) => AddedFunc2(EditSwimelane(Swimelaneid,background,swimlanename))}>
                            edit
                        </span></Button>
                        </p>
                    </div>

                    <Row id={'swimelane_'+Swimelaneid} style={{display:'flex'}} >
                        {TMPbuildCreateTaskCard}
                    </Row>
                </>
            )
        }
        buildSwimelanes.push(
            <>
                {Swimelane}
            </>
        )
    } else {
        let TMPCreateTaskCard = [];
        if (Column !== true && typeof Column !== "boolean" && Column?.length > 0
        ) {


            for (let c = 0; c < Column.length; c++) {
                let buildCreateTask = [];
                let counter = 0;

                if (response !== true && typeof response !== "boolean" && response?.length > 0
                ) {
                    let Data = (fetchSortData.length === 0 ? response : fetchSortData);
                    for (let x = 0; x < Data.length; x++) {

                        if (Data[x].column.id === Column[c].id) {
                            counter++
                            buildCreateTask.push(<>
                                <CreateTaskCard Store={Store} ProjectId={Data[x].project_id} priorität={Data[x].priority}
                                                position={(Data[x].position !== null ? Data[x].position : x)}
                                                id={Data[x].task_id} avatarID={1}
                                                title={Data[x].regard} now={100} variant={'success'}
                                                status={'Open'} daysColor={'248, 98, 27'} daysLeft={2} task={1}
                                                allTask={1}
                                                teamMember={true} date={Data[x].created_at} text={Data[x].instanz}
                                                background={(Data[x].instanz !== undefined && Data[x].instanz !== null && Data[x].instanz !== '' ? '#e5f1fd' : 'white')}/>
                            </>)
                        }
                    }
                }

                let cid = Column[c].id;

                TMPCreateTaskCard.push(
                    <>
                        <Col>
                            <h6 className={'text-uppercase'} style={{
                                borderBottom: '2px solid #F8621B',
                                paddingBottom: '15px'
                            }}>{Column[c].name} ({counter})</h6>
                            <div className={'nested-sortable'} data-columnID={cid}  data-swimelane={null}
                                 style={{minHeight: (buildCreateTask.length > 0 ? 'auto' : '60px')}}>
                                {buildCreateTask}
                            </div>
                            <br/>
                            <p onClick={() => openNew(cid,0)} className={'text-center'} style={{cursor:'pointer'}}>
                            <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                                add
                            </span>
                                Add new task
                            </p>
                        </Col>
                    </>
                )

            }
        }
        buildSwimelanes.push(TMPCreateTaskCard)
    }



    const handlesubmit = (e:any) => {
        const form = e.currentTarget
        e.preventDefault()
        let Data = init2(form)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(Data);

       var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +"yoptimize/new/task/", requestOptions)
            .then(response => response.text())
            .then(result => successForm(result))
            .catch(error => errorForm(error));
    };

    const successForm = (result:any) => {
        setFetchData(Date.now())
        setSelectcolumn(0)
        handleClose()
    }

    const errorForm = (error:any)=> {
        setSelectcolumn(0)
        handleClose()
    }

        const FormModal = [
            {
                formType: 'input',
                label: IntlMessageValue('de', 'common.title', Store),
                required: true,
                type: 'text',
                name: 'regard',
                id: "newCalenderTitle",
                value: ''
            },
            /*{
                formType: 'textarea',
                label: IntlMessageValue('de', 'common.description', Store),
                type: 'textarea',
                max: 800,
                name: 'description',
                id: 'newCalenderDescription',
                placeholder: '',
                row: 6,
                value: ''
            },
            SunEditorBig(
                '',
                'content',
                true,
                { lang: 'de', height: '250px;', config: SunEditorConfig() },
                'description',
                '',
                '',
                '',
                '',
                '',
                'classic',
                '300'
            ),*/
            {
                formType: 'input',
                label: 'swimlane_id',
                type: 'hidden',
                name: 'swimlane_id',
                id: "newCalenderTitle",
                value: '2'
            },
            {
                formType: 'input',
                label: 'project_id',
                type: 'hidden',
                name: 'project_id',
                id: "newCalenderTitle",
                value: id
            },
            {
                formType: 'input',
                label: 'support_type_id',
                type: 'hidden',
                name: 'support_type_id',
                id: "newCalenderTitle",
                value: '4'
            },
            {
                formType: 'input',
                label: 'status_id',
                type: 'hidden',
                name: 'status_id',
                id: "newCalenderTitle",
                value: '2'
            },
            {
                formType: 'input',
                label: 'task_id',
                type: 'hidden',
                name: 'task_id',
                id: "newCalenderTitle",
                value: (response !== true && typeof response !== "boolean" && response?.length > 0 && response[0].task_id ? response[0].task_id.split('-')[0]+'-' :  (name != null ?  name.charAt(0).toLocaleUpperCase() + name.substr(-1).toLocaleUpperCase()+'-' : ''))
            },
            {
                formType: 'input',
                label: 'member',
                type: 'hidden',
                name: 'member',
                id: "newCalenderTitle",
                value: localStorage.getItem('username')
            }
        ]




    const AddedFunc = (taskID:any,ProjectID:any,indiId?:any) => {


        let Dialog = document.getElementById((indiId !== undefined && indiId !== null ? indiId : 'addDialogMember')) as HTMLDivElement;
        Dialog.classList.add('show')
        Dialog.style.display = 'block';


        let Task = document.getElementById('TaskId') as HTMLInputElement;
        if(taskID !== null) {
            if(Task !== null) {
                Task.value = taskID;
            }
        }


        let Project = document.getElementById('ProjectId') as HTMLInputElement;

        if(ProjectID !== null) {
            if(Project !== null){
                Project.value = ProjectID
            }
        }


    }

    const AddedFunc2 = (form?:any,footer?:any,mode?:any) => {


            let Dialog = document.getElementById('addDialogdynamik') as HTMLDivElement;
            Dialog.classList.add("show");
            Dialog.style.display = 'block';
            if(form !== undefined) {
                let newElement = form
                let temp = document.createElement('div')
                ReactDOM.render(newElement, temp)
                let ele = document.getElementById('injectModalTask') as HTMLDivElement
                if(ele !== null) {
                    ele.innerHTML = ''
                    ele.prepend(temp)
                }
            }
        }



    const CloseMember = (indiId?:any,indclear?:any) => {
        let Dialog = document.getElementById((indiId !== undefined && indiId !== null ? indiId : 'addDialogMember')) as HTMLDivElement;
        Dialog.style.display = 'none';

        if(indclear !== null && indclear !== undefined) {
            for(let i =0;i<indclear.length;i++) {
                let tmp = document.getElementById(indclear[i].id) as HTMLInputElement;

                if(tmp !== null) {
                    tmp.value = indclear[i].value;
                }
            }
        }

        let Task = document.getElementById('TaskId') as HTMLInputElement;

        if(Task !== null) {
            Task.value = '';
        }

        let Project = document.getElementById('ProjectId') as HTMLInputElement;

        if(Project !== null){
            Project.value = ''
        }
    }

    const setter = () => {



        for (let x = 1; x < 17; x++) {
            if(y === x) {
                if(document.getElementById(x + 'avatarGroupadded') as HTMLDivElement !== null) {

                } else
                if (document.getElementById(x + 'avatarGroupadded') as HTMLDivElement === null) {
                    appendIcon(true, x + 'avatarGroup',AddedFunc,ProjectId,1)

                }
            }
            y++;
        }
    }

    useEffect(setter, [setter]);
    useEffect(() => {
        let editorText = (document.getElementById('htmleditor') as HTMLDivElement)
        if(editorText !== null) {
            let text = (editorText.children[0] as HTMLTextAreaElement)
            if(text !== null) {
                text.id='descriptionTextarea'
            }
        }
    })
    useEffect(() => {

        let nestedSortables = document.getElementsByClassName('nested-sortable')
        for (var i = 0; i < nestedSortables.length; i++) {
            new Sortable(nestedSortables[i] as HTMLElement, {
                group: 'nested',
                animation: 150,
                fallbackOnBody: true,
                swapThreshold: 0.65,
                onEnd: (evt) => {
                    let newcid;
                    let oldcid;
                    let taskidmove;
                    let projectIDmove;

                    oldcid = ((evt.from as HTMLDivElement).dataset.columnid);
                    (evt.from as HTMLDivElement).style.minHeight = '60px';
                    newcid = ((evt.to as HTMLDivElement).dataset.columnid)

                    taskidmove = (evt.clone as HTMLDivElement).dataset.taskid

                    projectIDmove = (evt.clone as HTMLDivElement).dataset.projectid
                    if(oldcid === newcid) {
                        console.log('kein Fetch')
                    } else {
                        var raw = JSON.stringify({
                            lastupdatefrom: localStorage.getItem('username'),
                            column_id: newcid
                        })


                        var requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' +
                                    JSON.parse(localStorage.getItem('token') as string)
                            },
                            body: raw
                        }


                        fetch(ApiRoot()+'yoptimize/project/'+projectIDmove+'/setColumn/task/'+taskidmove+'/oldCid/'+oldcid+'/', requestOptions)
                            .then((response) => response.text())
                            .then((result) => console.log(result))
                            .catch((error) => console.log(error))
                    }


                    let SortList = document.getElementsByClassName('nested-sortable')

                    for(let sort = 0; sort < SortList.length;sort++) {
                        let SortListElement = SortList[sort].childNodes

                        for(let element =0;element<SortListElement.length;element++){
                            (SortListElement[element] as HTMLDivElement).dataset.position = ""+element+"";
                            (SortListElement[element] as HTMLDivElement).dataset.swimelane = ""+(SortList[sort] as HTMLDivElement).dataset.swimelane+"";
                            let positionColumn = (SortList[sort] as HTMLDivElement).dataset.columnid
                            let newPosition = (SortListElement[element] as HTMLDivElement).dataset.position
                            let PTaskid = (SortListElement[element] as HTMLDivElement).dataset.taskid
                            let PProjectid = (SortListElement[element] as HTMLDivElement).dataset.projectid
                            let Pswimlane_id = (SortListElement[element] as HTMLDivElement).dataset.swimelane

                            var rawPosition = JSON.stringify({
                                position: newPosition,
                                swimlane_id: Pswimlane_id
                            })
                            var requestOptionsPosition = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization:
                                        'Bearer ' +
                                        JSON.parse(localStorage.getItem('token') as string)
                                },
                                body: rawPosition
                            }

                            fetch(ApiRoot()+'yoptimize/project/'+PProjectid+'/setPosition/task/'+PTaskid+'/Cid/'+positionColumn+'/', requestOptionsPosition)
                                .then((response) => response.text())
                                .then((result) => console.log(result))
                                .catch((error) => console.log(error))
                        }
                    }

                },
                onUpdate: (evt) => {

                    // Sortierung
                    let SortList = document.getElementsByClassName('nested-sortable')

                    for(let sort = 0; sort < SortList.length;sort++) {
                        let SortListElement = SortList[sort].childNodes
                        for(let element =0;element<SortListElement.length;element++){
                            (SortListElement[element] as HTMLDivElement).dataset.position = ""+element+"";
                            (SortListElement[element] as HTMLDivElement).dataset.swimelane = ""+(SortList[sort] as HTMLDivElement).dataset.swimelane+"";
                        }
                    }
                },

                onClone: (evt) => {

                    // Sortierung
                    let SortList = document.getElementsByClassName('nested-sortable')

                    for(let sort = 0; sort < SortList.length;sort++) {
                        let SortListElement = SortList[sort].childNodes
                        for(let element =0;element<SortListElement.length;element++){
                            (SortListElement[element] as HTMLDivElement).dataset.position = ""+element+"";
                            (SortListElement[element] as HTMLDivElement).dataset.swimelane = ""+(SortList[sort] as HTMLDivElement).dataset.swimelane+"";
                        }
                    }
                }
            })
        }



    })

    const Configuration = {
        tableHeader: [
            <IntlMessage Store={Store} messageId="common.id" />,
            <IntlMessage Store={Store} messageId="common.title" />,
            <IntlMessage Store={Store} messageId="Instanz" />,
            <IntlMessage Store={Store} messageId="Column" />,
            <IntlMessage Store={Store} messageId="Team" />,
        ],
        tableHeaderType: ['integer','string','string','string','string'],
        tableContent: ['task_id','regard','instanz','column.name','member']
    }

    const saveSwimelane = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            name: formdata.get('name'),
            color: formdata.get('color'),
            project_id:id
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/new/swimelanes/', requestOptions)
            .then((response) => response.text())
            .then((result) => successFormswimelane(result,CloseMember,CloseMemberID,[{id:'saveSwimelanename',value:''},{id:'saveSwimelanecolor',value:'#10B82B'}]))
            .catch((error) => errorFormswimelane(error,CloseMember,CloseMemberID))
    }

    const successFormswimelane = (result:any,close:any,mode?:any,indiclear?:any) => {
        setFetchData(Date.now())
        if(mode !== undefined && mode !== null) {
            close(mode,indiclear)
        } else {
            close()
        }

    }

    const errorFormswimelane = (error:any,close:any,mode?:any)=> {
        if(mode !== undefined && mode !== null) {
            close(mode)
        } else {
            close()
        }
    }

    const setterEditor = (id:any,id2:any) => {
        (document.getElementById(id) as HTMLDivElement).style.display = 'block';
        (document.getElementById(id2) as HTMLDivElement).style.display = 'none'
    }

    const setEditorContent = (value:any,textarea?:any) =>{
        setValue(value)
        if(textarea !== undefined) {
            (document.getElementById('descriptionTextarea') as HTMLTextAreaElement).value = value
        }
    }
    if(typeof singleProject !== "boolean" && singleProject?.length > 0) {
        return (
            <>
                <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>
                    <h3>{singleProject[0].title}</h3>
                    <Link className={'btn btn-info float-start'}
                          state={{ projectid: singleProject[0].parent }} to={(singleProject[0].parent === "0" ? '/myprojects/new/projects/1' : '/myprojects/new/projects/1/' + singleProject[0].parent + '/test')}
                          style={{background: 'white', borderColor: '#23AAE1', color: '#23AAE1'}}>
                        Back
                    </Link>
                    <Search navigate={navigate}/>
                    {refresh(setFetchData)}
                    <DynamikOffCanvas
                        openButtonLabel={<><span className="material-icons" style={{verticalAlign: 'bottom'}}>
                    add
                    </span>
                            Add new task</>}
                        saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                        closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                        title={'Create new task'}
                        ClassOpenButton={'float-end'}
                        ClassSaveButton={'float-end'}
                        body={<>
                            <div className="modal-dialog" style={{marginRight: '-23px'}}>

                            </div>
                            {PielersForm(FormModal)}
                            <Button variant="outline-primary"
                                    onClick={() => setterEditor('htmleditor', 'markdowneditor')}>HTML Editor</Button>
                            <Button variant="outline-primary"
                                    onClick={() => setterEditor('markdowneditor', 'htmleditor')}>Markdown
                                Editor</Button>

                            <div id={'htmleditor'} style={{display: 'block'}}>
                                <SunEditor
                                    key={'test'}

                                    name={'description'}
                                    setContents={value}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id',
                                            p: 'id',
                                            h: 'id'
                                        }
                                    }}
                                    height={'450px'}
                                    onChange={(value) => setEditorContent(value)}
                                />
                            </div>
                            <div id={'markdowneditor'} style={{display: 'none'}}>
                                <MarkdownEditor
                                    value={value.replaceAll('<p>', '').replaceAll('</p>', '')}
                                    height={'450px'}
                                    visible={true}
                                    onChange={(value, viewUpdate) => setEditorContent(value, true)}

                                />

                            </div>
                            <Row>
                                <Col className={'col-10'}><Form.Group className="mb-3">
                                    <Form.Label className={'w-100'}>Priority <span className="material-icons float-end"
                                                                                   onClick={() => switchdisplay('AgileTaskPriorities')}>
                                    info
                                    </span></Form.Label>
                                    <Form.Select aria-label="Default select example" name={'priority'} required={true}>
                                        <option label="Priority select" value=""></option>
                                        <option value="0">P0</option>
                                        <option value="1">P1</option>
                                        <option value="2">P2</option>
                                        <option value="3">P3</option>
                                        <option value="4">P4</option>
                                        <option value="5">P5</option>
                                        <option value="6">P6</option>
                                    </Form.Select>
                                </Form.Group></Col>
                                <Col className={'col-2'}>
                                    <Form.Label>Farbe</Form.Label>
                                    <Form.Control
                                        type="color"
                                        name={'color'}
                                        id={'saveSwimelanecolor'}
                                        defaultValue="#FFFFFF"
                                    /></Col>
                            </Row>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Responsibility</Form.Label>
                                <Form.Select aria-label="Default select example" name={'responsibility'}
                                             required={true}>
                                    <option label="Responsibility select" value=""></option>
                                    <option value="assign a user">Do not assign a user</option>
                                    {UserHolder}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Due on</Form.Label>
                                <Form.Control type="datetime-local" name={'due_on'}/>
                            </Form.Group>
                            <Form.Control name={'column_id'} type="hidden"
                                          defaultValue={(selectcolumn !== 0 ? selectcolumn : 2)}/>
                            <Form.Control name={'swimlane_id'} type="hidden"
                                          defaultValue={(selectswimelane !== 0 ? selectswimelane : firstswimelane)}/>
                            <Form.Control name={'creator'} type="hidden"
                                          defaultValue={localStorage.getItem('username') as string}/>
                        </>}
                        handlesubmit={handlesubmit}
                        handleShow={handleShow}
                        show={show}
                        id={'TaskNew'}
                        setShow={setShow}
                        handleClose={CloseDynmaikModal}
                    />
                    <Button variant="outline-primary" className={'float-end ml-3'}
                            style={{position: 'relative', right: '5px', zIndex: '96'}}
                            onClick={(e) => AddedFunc(null, null, 'addDialogSwimelanes')}>
                <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                    add
                </span>
                        New swimlane
                    </Button>
                    <br style={{clear: 'both'}}/>
                    <br/>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">Title</InputGroup.Text>
                                <Form.Control type="text"
                                              onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'regard', e, filter, setfilter)}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">Description</InputGroup.Text>
                                <Form.Control type="text"
                                              onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'description', e, filter, setfilter)}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">Instanz</InputGroup.Text>
                                <Form.Control type="text"
                                              onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'instanz', e, filter, setfilter)}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">Responsibility</InputGroup.Text>
                                <Form.Control type="text"
                                              onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'responsibility', e, filter, setfilter)}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <br/>
                    <BoardTableSwitcher boardId={'board'} TableID={'TableMakerSupport'} HistoryID={'history'}
                                        archiv={setArchiv} del={setDel}/>
                    <br/>
                    <div id={'board'} style={{display: 'block'}}>
                        <Row>
                            {buildSwimelanes}
                        </Row>

                    </div>
                    <TableMakerSupport id={'TableMakerSupport'}
                                       Configuration={Configuration}
                                       responseOld={response}
                                       response={fetchSortData}
                                       setFetchSortData={setFetchSortData}
                                       setFetchData={setFetchData}
                                       navigate={navigate}
                                       url={'/task/single/[project_id]/[task_id]'}
                                       params={['project_id', 'task_id']}
                    />
                    <div id={'history'} style={{display: 'none', padding: '20px'}}>
                        <div className={'tasktimeline'}>
                            <p><strong>Task Timeline</strong> <span
                                style={{color: '#23AAE1', textDecoration: 'underline'}}>History</span></p>
                        </div>
                        <Card style={{borderRadius: '0px'}}>

                            <Card.Body>
                                <section>
                                    <ul className="timeline">
                                        {BuildLastActivities}
                                    </ul>
                                </section>
                            </Card.Body>

                        </Card>
                    </div>
                    <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                         id="addDialogMember"
                         style={{overflowY: 'auto', minWidth: '400px', top: '60px', right: '10px', width: '40%'}}>
                        <div className="offcanvas-body">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className="modal-title h4">Added Team Member</div>
                                        <button type="button" className="btn-close" aria-label="Close"
                                                onClick={() => CloseMember()}/>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col">
                                                <div className="mb-3">
                                                    <input name={'TaskId'} type="hidden" id="TaskId"
                                                           className="form-control" value=""/>
                                                    <input name={'ProjectId'} type="hidden" id="ProjectId"
                                                           className="form-control" value=""/>
                                                    <Form.Select name={'MemberId'} aria-label="Default select example">
                                                        <option></option>
                                                        <option value="1">Max Mustermann</option>
                                                        <option value="2">John Doe</option>
                                                    </Form.Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary"
                                                onClick={() => CloseMember()}>Close
                                        </button>
                                        <button type="button" className="btn btn-primary float-end">Added Member
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                         id="addDialogSwimelanes"
                         style={{overflowY: 'auto', minWidth: '400px', top: '60px', right: '10px', width: '40%'}}>
                        <div className="offcanvas-body">
                            <div className="modal-dialog">
                                <Form
                                    id={'form001'}
                                    className={'formControl'}
                                    noValidate
                                    onSubmit={(e) =>
                                        saveSwimelane(e, CloseMember, 'addDialogSwimelanes')
                                    }
                                >
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <div className="modal-title h4">Add swimlane</div>
                                            <button type="button" className="btn-close" aria-label="Close"
                                                    onClick={() => CloseMember('addDialogSwimelanes', [{
                                                        id: 'saveSwimelanename',
                                                        value: ''
                                                    }, {id: 'saveSwimelanecolor', value: '#10B82B'}])}/>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="mb-3">
                                                        <Row>
                                                            <Col className={'col-10'}> <Form.Group className="mb-3"
                                                                                                   controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Name</Form.Label>
                                                                <Form.Control type="text" id={'saveSwimelanename'}
                                                                              name={'name'} required={true}/>
                                                            </Form.Group></Col>
                                                            <Col className={'col-2'}> <Form.Label
                                                                htmlFor="exampleColorInput">Color</Form.Label>
                                                                <Form.Control
                                                                    type="color"
                                                                    name={'color'}
                                                                    id={'saveSwimelanecolor'}
                                                                    defaultValue="#10B82B"
                                                                /></Col>
                                                        </Row>
                                                        <input name={'ProjectId'} type="hidden" id="ProjectId"
                                                               className="form-control" defaultValue={id}/>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary"
                                                    onClick={() => CloseMember('addDialogSwimelanes', [{
                                                        id: 'saveSwimelanename',
                                                        value: ''
                                                    }, {id: 'saveSwimelanecolor', value: '#10B82B'}])}>Close
                                            </button>
                                            <button type="submit" className="btn btn-primary float-end">Add swimlane
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                         id="addDialogdynamik"
                         style={{overflowY: 'auto', minWidth: '400px', top: '60px', right: '10px', width: '40%'}}>

                        <div className="offcanvas-body">
                            <div id="injectModalTask"></div>
                        </div>
                    </div>
                    <div id="AgileTaskPriorities" className="table-list tooltip-agile-row"
                         style={{display: 'none', marginTop: '160px'}}>
                        <div className="table-list-header"><strong>Priorität der Aufgaben (in abfallender
                            Folge)</strong>

                        </div>
                        <div className="agile-row table-border-left agile-bg-red">
                            <div className="table-list-details agile-table-list-title">
                                <strong>P1</strong>
                            </div>
                            <div className="table-list-details agile-table-list-details">
                                <ul className="">
                                    <li className="">Oberste Priorität</li>
                                </ul>
                            </div>
                        </div>
                        <div className="agile-row table-border-left agile-bg-red">
                            <div className="table-list-details agile-table-list-title">
                                <strong>P2</strong>
                            </div>
                            <div className="table-list-details agile-table-list-details">
                                <ul className="">
                                    <li className="">Hohe Priorität</li>
                                </ul>
                            </div>
                        </div>
                        <div className="agile-row table-border-left agile-bg-green">
                            <div className="table-list-details agile-table-list-title">
                                <strong>P3</strong>
                            </div>
                            <div className="table-list-details agile-table-list-details">
                                <ul className="">
                                    <li className="">Laufende Arbeiten</li>
                                    <li className="">Fortlaufende Entwicklung</li>
                                </ul>
                            </div>
                        </div>
                        <div className="agile-row table-border-left agile-bg-green">
                            <div className="table-list-details agile-table-list-title">
                                <strong>P4</strong>
                            </div>
                            <div className="table-list-details agile-table-list-details">
                                <ul className="">
                                    <li className="">Geringe Priorität</li>
                                    <li className="">Kein Zeitplan</li>
                                </ul>
                            </div>
                        </div>
                        <div className="agile-row table-border-left agile-bg-cream">
                            <div className="table-list-details agile-table-list-title">
                                <strong>P5</strong>
                            </div>
                            <div className="table-list-details agile-table-list-details">
                                <ul className="">
                                    <li className="">Weniger wichtig</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return(
            <></>
        )
    }
}

export default MyTask;


export  const savearchiv = (e:any,id:any,ticketID:any,archiv:any,setFetchData:any) => {
    e.preventDefault()
    let data = e.currentTarget

    var raw = JSON.stringify({
        lastupdatefrom: localStorage.getItem('username'),
        archiv:archiv
    })

    var requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization:
                'Bearer ' +
                JSON.parse(localStorage.getItem('token') as string)
        },
        body: raw
    }

    fetch(ApiRoot()+'yoptimize/project/'+id+'/archiv/task/'+ticketID+'/', requestOptions)
        .then((response) => response.text())
        .then((result) =>  setFetchData(Date.now()))
        .catch((error) => console.log(error))
}

export  const savedelete = (e:any,id:any,ticketID:any,del:any,setFetchData:any) => {
    e.preventDefault()
    let data = e.currentTarget

    var raw = JSON.stringify({
        lastupdatefrom: localStorage.getItem('username'),
        delete:del
    })

    var requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization:
                'Bearer ' +
                JSON.parse(localStorage.getItem('token') as string)
        },
        body: raw
    }

    fetch(ApiRoot()+'yoptimize/project/'+id+'/del/task/'+ticketID+'/', requestOptions)
        .then((response) => response.text())
        .then((result) =>  setFetchData(Date.now()))
        .catch((error) => console.log(error))
}
