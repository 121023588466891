import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from 'react-bootstrap'
import Offcanvas from 'react-bootstrap/Offcanvas';

const DynamikModal = (props:{title:any,body:any,saveButtonLabel:any,openButtonLabel:any,closeButtonLabel:any,handlesubmit:any,handleShow:any,show:any,setShow:any,handleClose:any,ClassOpenButton?:any,ClassSaveButton?:any,indiButton?:any,id?:any,SaveID?:any,saveDisabled?:any}) => {

    return (
        <>
            {props.indiButton !== undefined ?
                props.openButtonLabel
                :

                    <Button variant="primary" className={props.ClassOpenButton} onClick={props.handleShow}>
                        {props.openButtonLabel}
                    </Button>

            }
            <div id={'Modal'}>
                <Modal show={props.show} id={props.id} onHide={props.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form
                            id={'form001'}
                            className={'formControl'}
                            onSubmit={(e) =>props.handlesubmit(e)} >
                            {props.body}
                            <Button id={props.SaveID !== null && props.SaveID !== null ? props.SaveID : 'save'} disabled={props.saveDisabled !== null && props.saveDisabled !== undefined ? props.saveDisabled : false } variant="primary" type={'submit'} className={props.ClassSaveButton}>
                                {props.saveButtonLabel}
                            </Button>
                            <Button variant="secondary" onClick={props.handleClose}>
                                {props.closeButtonLabel}
                            </Button>
                        </Form></Modal.Body>
                </Modal>
            </div>
        </>
    );

}

export default DynamikModal;

export const DynamikOffCanvas = (props:{title:any,body:any,saveButtonLabel:any,openButtonLabel:any,closeButtonLabel:any,handlesubmit:any,handleShow:any,show:any,setShow:any,handleClose:any,ClassOpenButton?:any,ClassSaveButton?:any,indiButton?:any,id?:any,SaveID?:any,saveDisabled?:any}) => {

    return (
        <>
            {props.indiButton !== undefined ?
                props.openButtonLabel
                :

                <Button variant="primary" className={props.ClassOpenButton} onClick={props.handleShow}>
                    {props.openButtonLabel}
                </Button>

            }
            <Offcanvas show={props.show} onHide={props.handleClose} id={props.id} backdrop={false} placement={'end'} style={{overflowY:'auto', overflowX:'unset',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{props.title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{overflowX:'hidden'}}>
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        onSubmit={(e) =>props.handlesubmit(e)} >
                        {props.body}
                        <Button id={props.SaveID !== null && props.SaveID !== null ? props.SaveID : 'save'} disabled={props.saveDisabled !== null && props.saveDisabled !== undefined ? props.saveDisabled : false } variant="primary" type={'submit'} className={props.ClassSaveButton}>
                            {props.saveButtonLabel}
                        </Button>
                        <Button variant="secondary" onClick={props.handleClose}>
                            {props.closeButtonLabel}
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );

}

export const DynamikOffCanvaspv = (props:{title:any,body:any,saveButtonLabel:any,openButtonLabel:any,closeButtonLabel:any,handlesubmit:any,handleShow:any,show:any,setShow:any,handleClose:any,ClassOpenButton?:any,ClassSaveButton?:any,indiButton?:any,id?:any,SaveID?:any,saveDisabled?:any,successForm?:any,errorForm?:any,setFetchData?:any,saveRoute?:any}) => {

    return (
        <>
            {props.indiButton !== undefined ?

                props.openButtonLabel
                :

                <Button variant="primary" className={props.ClassOpenButton} onClick={props.handleShow}>
                    {props.openButtonLabel}
                </Button>

            }
            <Offcanvas show={props.show} onHide={props.handleClose} id={props.id} backdrop={false} placement={'end'} style={{overflowY:'auto', overflowX:'unset',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{props.title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{overflowX:'hidden'}}>
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        onSubmit={(e) =>props.handlesubmit(e,props.successForm,props.errorForm,props.saveRoute)} >
                        {props.body}
                        <Button id={props.SaveID !== null && props.SaveID !== null ? props.SaveID : 'save'} disabled={props.saveDisabled !== null && props.saveDisabled !== undefined ? props.saveDisabled : false } variant="primary" type={'submit'} className={props.ClassSaveButton}>
                            {props.saveButtonLabel}
                        </Button>
                        <Button variant="secondary" onClick={props.handleClose}>
                            {props.closeButtonLabel}
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );

}
