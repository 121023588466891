import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import NavDropdown from "react-bootstrap/NavDropdown";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import ReactDOM from "react-dom";
import Form from "react-bootstrap/Form";
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {getToken} from "../../../../../@WUM/core/component/const/getToken";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
import {swimmLane} from "../Pages/MyUnderProjects";
import {allowSaveMove, savearchivProject, savedeleteProject} from "../Pages/MyProjects";
import {dateFormat} from "../../../../../@WUM/core/component/const/dateFormat";
import Stack from "react-bootstrap/Stack";
import {savearchiv, savedelete} from "../Pages/MyTask";


const CreateProjectCard = (props:{Store:any,ClassN?:any,id:any,isMain?:any,archiv?:any,delete?:any,swimelane?:any,background?:any,Buildsubproject?: JSX.Element[],BuildAllsubproject?:JSX.Element[],SetSwimlaneID?: (value: ((prevState: string) => string) | string) => void ,setFetchData?:(value: ((prevState: number) => number) | number) => void ,parent?:any,description?:any,position?:any,title?:any,now?:any,variant?:any,status?:any,daysColor?:any,daysLeft?:any,task?:any,allTask?:any,teamMember?:any,date?:any,avatarID?:any}) => {



    const AddedFunc = (form?:any,footer?:any,mode?:any,modeID?:any) => {

        if(mode !== undefined && mode !== null) {
            let Dialogs = document.getElementById((modeID !== undefined && modeID !== null && modeID !== '' ? modeID :'addDialogInternalConnection')) as HTMLDivElement;
            Dialogs.classList.add("show");
            Dialogs.style.display = 'block';
        } else {
            let Dialog = document.getElementById('addDialogdynamik') as HTMLDivElement;
            Dialog.classList.add("show");
            Dialog.style.display = 'block';
            if(form !== undefined) {
                let newElement = form
                let temp = document.createElement('div')
                ReactDOM.render(newElement, temp)
                let ele = document.getElementById('injectModalTask') as HTMLDivElement
                if(ele !== null) {
                    ele.innerHTML = ''
                    ele.prepend(temp)
                }
            }
        }
    }

    const CloseMember = (indiId?:any,indclear?:any) => {
        let Dialog = document.getElementById((indiId !== undefined && indiId !== null ? indiId : 'addDialogMember')) as HTMLDivElement;
        Dialog.style.display = 'none';

        if(indclear !== null && indclear !== undefined) {
            for(let i =0;i<indclear.length;i++) {
                let tmp = document.getElementById(indclear[i].id) as HTMLInputElement;

                if(tmp !== null) {
                    tmp.value = indclear[i].value;
                }
            }
        }

        let Task = document.getElementById('TaskId') as HTMLInputElement;

        if(Task !== null) {
            Task.value = '';
        }

        let Project = document.getElementById('ProjectId') as HTMLInputElement;

        if(Project !== null){
            Project.value = ''
        }
    }

    const EditProject = () => {
        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                           editProject(e,props.id)
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Edit project</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Titel</Form.Label>
                                    <Form.Control type="text" name={'title'} defaultValue={props.title} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Beschreibung</Form.Label>
                                    <Form.Control as="textarea" rows={6} name={'description'} defaultValue={props.description} />
                                </Form.Group>
                                <Form.Label>Farbe</Form.Label>
                                <Form.Control
                                    type="color"
                                    name={'color'}
                                    id={'saveSwimelanecolor'}
                                    defaultValue="#FFFFFF"
                                />
                               <Form.Control name={'id'} type="hidden" defaultValue={props.id} />
                                <br/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}>Close
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >Save</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </>
        )}
    const editProject = (e:any,id:any) => {
        const form = e.currentTarget
        e.preventDefault()
        let Data = init2(form)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(Data);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +"yoptimize/edit/project/"+id+'/', requestOptions)
            .then(response => response.text())
            .then(result => successForm(result))
            .catch(error => errorForm(error));
    };

    const successForm = (result:any) => {
        if (props.setFetchData) {
            props.setFetchData(Date.now())
        }
        CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])
    }

    const errorForm = (error:any)=> {
        CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])
    }




    const setSwim = (e:any) => {

        if (props.SetSwimlaneID !== undefined) {
            props.SetSwimlaneID(e.target.value)
        }

        if (props.setFetchData !== undefined) {
            props.setFetchData(Date.now())
        }
    }
    const MoveProject = () => {
        /* @ts-ignore */
        let SwimLaneHolder = [];
        let localSwimLanes = JSON.parse(localStorage.getItem('swimlanesSubprojectLocal') as string)

        for (let us = 0; us < localSwimLanes?.length; us++) {
            SwimLaneHolder.push(
                <>
                    <option value={localSwimLanes[us].id}>{localSwimLanes[us].name}</option>
                </>
            )
        }
        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        onSubmit={(e) =>
                            moveProject(e)
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Move project</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                <Form.Select name={'parent'} aria-label="Default select example"  required={true}
                                             onChange={(e) => swimmLane(e,props.SetSwimlaneID,props.setFetchData)}>
                                    <option label={"Select project"} value={''}></option>
                                    <option label={"as normal project"} value={'0'}></option>
                                    {props.BuildAllsubproject}
                                </Form.Select>
                                <Form.Control name={'id'} type="hidden" defaultValue={props.id} />
                                <br />
                                <Form.Select name={'swimlane_id'} id={'MoveHolderProject'} aria-label="Default select example"
                                             required={true}>
                                </Form.Select>
                                <br/>
                                <Form.Check
                                    className={'text-danger'}
                                    type={'checkbox'}
                                    label={'Warning, all tasks of the main project are no longer displayed. Alternatively, you can create a new empty project for better organization'}
                                    id={`disabled-default-`}
                                    onClick={(e)=> allowSaveMove(e,'CreateEditProjects')}
                                />
                                <br/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}>Close
                                </button>
                                <button type="submit" id={'CreateEditProjects'} className="btn btn-primary float-end" disabled={true}>Move</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </>
        )}

    const moveProject = (e:any) => {
        const form = e.currentTarget
        e.preventDefault()
        var formdata = new FormData(form)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify({
            swimlane_id: formdata.get('swimlane_id')
        })


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +"yoptimize/move/project/"+formdata.get('id')+"/main/"+formdata.get('parent')+"/", requestOptions)
            .then(response => response.text())
            .then(result => successForm(result))
            .catch(error => errorForm(error));
    };

    let ColorMapStatus = [
        {title: 'Open',color:'#F8621B'},
        {title: 'In Progess',color:'#2EB0D9'}
    ]

    let color;

    for(let x=0;x<ColorMapStatus.length;x++){
        if(ColorMapStatus[x].title === props.status){
            color = ColorMapStatus[x].color;
        }
    }

    let ColorMapProgressBar = [
        {title: 'danger',color:'#dc3545'},
        {title: 'success',color:'#28a745'},
        {title: 'warning',color:'#ffc107'}
    ]

    let colorProess

    for(let x=0;x<ColorMapProgressBar.length;x++){
        if(ColorMapProgressBar[x].title === props.variant){
            colorProess = ColorMapProgressBar[x].color;
        }
    }





    return(<Col className={props.ClassN} data-projectID={props.id} data-swimelane={props.swimelane} data-position={props.position} style={{padding:
                (props.teamMember !== undefined && props.teamMember !== '' ? 0 : 'calc(var(--bs-gutter-x) * .5)')
        }}>

            <Card style={{ background:props.background,width: '100%',marginBottom:'10px',cursor:'move'}}>
                <Card.Body>
                    <Card.Title>
                        <Row>
                            <Col className={'col-9'}><small>
                                <Link state={{ projectid: props.id }} to={(props.isMain !== undefined && props.isMain !== null && props.isMain !== '0' ? '/myprojects/new/projects/1/'+props.id+'/'+props.title : '/mytask/new/projects/'+props.id+'/'+props.title)} style={{textDecoration:'none',color:'black'}} >
                                    <p  style={{marginBottom: '0'}}>{props.title !== undefined && props.title !== ''  ? props.title : 'Project App XYZ'}</p>
                            </Link></small></Col>
                            <Col className={'col-3'}><NavDropdown
                                className={'float-end text-right'}
                                title={
                                    <span>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#999999"
                                            className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                      <path
                                          d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                 </span>
                                }
                                id='collasible-nav-dropdown_dashboard_projects'>
                                <Link className={'dropdown-item'} to={(props.isMain !== undefined && props.isMain !== null && props.isMain !== '0' ? '/myprojects/new/projects/1/'+props.id+'/'+props.title : '/mytask/new/projects/'+props.id+'/'+props.title)} >
                                    Open
                                </Link>
                            <NavDropdown.Item onClick={(e) => AddedFunc(EditProject())}>Edit</NavDropdown.Item>
                            <NavDropdown.Item onClick={(e) => AddedFunc(MoveProject())}>Move as Subproject</NavDropdown.Item>
                                <NavDropdown.Item onClick={(e)=>savearchivProject(e,props.id,(props.archiv === "1" ? 0 : 1),props.setFetchData)}>{(props.archiv === "1" ? 'Restore project from archiv' : 'Archive project')} </NavDropdown.Item>
                                <NavDropdown.Item onClick={(e)=>savedeleteProject(e,props.id,(props.delete === "1" ? 0 : 1),props.setFetchData)}>{(props.delete === "1" ? 'Restore project from trash' : 'Delete project')} </NavDropdown.Item>
                            </NavDropdown> </Col>
                            {/*<Col className={'col-1'} style={{marginTop:'-5px'}}>

                             <span className="material-icons float-right" onClick={() => (document.getElementById('cardITem'+props.id) as HTMLDivElement).style.display = 'none'}>
                                    expand_less
                                </span>

                                <br/>
                                <span className="material-icons float-right" onClick={() => (document.getElementById('cardITem'+props.id) as HTMLDivElement).style.display = 'block'}>
                                    expand_more
                                </span>

                            </Col>*/}
                        </Row>
                               <small style={{fontSize:'small'}}>{
                                   dateFormat(
                                       props.date,
                                       localStorage.getItem('locale'),
                                       'us',
                                       true,
                                       false,
                                       props.Store
                                   )
                                   }</small>
                    </Card.Title>
                    <Card.Text  >
                        {props.isMain !== undefined && props.isMain === '1'
                        ?
                            <></>
                            :
                            <>
                            <div>
                            <p className={'float-start'}>Task done {props.task}/{props.allTask}</p>
                            <p className={'float-end'} style={{textAlign: 'right',color:colorProess}}>{props.now}%</p>

                            <br style={{clear:'both'}}></br>
                            </div>
                            <ProgressBar style={{height:'5px'}} variant={props.variant !== undefined && props.variant !== ''  ? props.variant : 'danger'} now={props.now} />
                            </>
                        }



                        <br/>
                        <div id={'cardITem'+props.id} style={{display:'none'}}>
                        {props.teamMember !== undefined && props.teamMember !== '' ?
                            <>
                                <Row>
                                    <Col md={8} xxl={8} xl={8} sm={8}>Team Member<br/>

                                        <AvatarGroup max={4} className={'float-start'} id={props.avatarID !== undefined && props.avatarID !== ''  ? props.avatarID+'avatarGroup' : 'avatarGroup'}>
                                            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                            <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                                            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                                            <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                                            <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
                                        </AvatarGroup>

                                        {/*  <Avatar className={'float-end teamMember '} style={{border: '1px solid #bdbdbd',background: 'white',color: 'blue'}}>
                                    <span className="material-icons">
                                        add
                                        </span>
                                            </Avatar>*/}
                                    </Col>
                                    <Col  md={4} xxl={4} xl={4} sm={4}><p style={{textAlign:'right'}}><br/>
                                        <span className="badge rounded-pill "
                                              style={{marginTop: '10px',backgroundColor: 'rgba('+props.daysColor+',0.4)',color:'rgba('+props.daysColor+')'}}>
                                    {props.daysLeft} days left</span>
                                    </p></Col>
                                </Row>
                            </>
                            :(<>
                                <Row>
                                    <Col>Status<br/>
                                        <span style={{color: color,textTransform:'uppercase'}}><strong>{props.status !== undefined && props.status !== ''  ? props.status : 'Open'}</strong></span></Col>
                                    <Col><p style={{textAlign:'right'}}><br/> <span className="badge rounded-pill "
                                                                                    style={{backgroundColor: 'rgba('+props.daysColor+',0.4)',color:'rgba('+props.daysColor+')'}}>
                                    {props.daysLeft} days left</span></p></Col>
                                </Row>
                            </>)}
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default CreateProjectCard;



export const CreateTaskCard = (props:{Store:any,state?:any,background?:any,ClassN?:any,ColPadding?:any,archiv?:any,delete?:any,setFetchData?:(value: ((prevState: number) => number) | number) => void ,label?:any,priorität?:any,comment?:any,swimelane?:any,id:any,position?:any,title?:any, ProjectId?:any,now?:any,variant?:any,status?:any,daysColor?:any,daysLeft?:any,task?:any,allTask?:any,teamMember?:any,date?:any,avatarID?:any,text?:any}) => {

    let ColorMapStatus = [
        {title: 'Open',color:'#F8621B'},
        {title: 'In Progess',color:'#2EB0D9'}
    ]

    let color;

    for(let x=0;x<ColorMapStatus.length;x++){
        if(ColorMapStatus[x].title === props.status){
            color = ColorMapStatus[x].color;
        }
    }

    let ColorMapProgressBar = [
        {title: 'danger',color:'#dc3545'},
        {title: 'success',color:'#28a745'},
        {title: 'warning',color:'#ffc107'}
    ]

    let colorProess

    for(let x=0;x<ColorMapProgressBar.length;x++){
        if(ColorMapProgressBar[x].title === props.variant){
            colorProess = ColorMapProgressBar[x].color;
        }
    }

    let colorset = [
        {color:'#4D4D4D'},
        {color:'#B71234'},
        {color:'#B71234'},
        {color:'#2EA02E'},
        {color:'#2EA02E'},
        {color:'#FFA5006E'},
        {color:'#FFA5006E'}
    ]

    let StackBadgeHolder = [];
    if(props.label !== undefined && props.label !== null) {
        for (let sl = 0; sl < props.label.length; sl++) {

            StackBadgeHolder.push(
                <span className="badge rounded-pill"
                      style={{background: props.label[sl].color,fontSize:'12px'}}>{props.label[sl].name}</span>
            )
        }
    }


    return(<Col className={props.ClassN} data-projectID={props.ProjectId}  data-taskID={props.id} data-swimelane={props.swimelane} data-position={props.position} style={{padding:
                (props.teamMember !== undefined && props.teamMember !== '' ? (props.ColPadding !== undefined ? props.ColPadding :'0') : 'calc(var(--bs-gutter-x) * .5)')
        }}>

            <Card style={{ background:(props.background !== undefined ? props.background : 'white'),width: '100%',marginBottom:'10px',cursor:'move'}}>
                <Card.Body>
                    <Card.Title>
                        <Row>
                        <Col className={'col-9'}><small>
                        <Link to={'/task/single/'+props.ProjectId+'/'+props.id} style={{textDecoration:'none',color:'black'}}>
                            <p style={{marginBottom: '0'}}>{props.title !== undefined && props.title !== ''  ? props.title : 'Task App XYZ'}</p>
                    </Link></small>
                        </Col><Col className={'col-3'} style={{padding: '0'}}>
                        <NavDropdown
                            className={'text-right'}
                            title={
                                <span>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#999999"
                                            className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                      <path
                                          d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                 </span>
                            }
                            id='collasible-nav-dropdown_dashboard_projects'>
                            <Link className={'dropdown-item'} to={'/task/single/'+props.ProjectId+'/'+props.id} >
                                Open
                            </Link>
                            <NavDropdown.Item onClick={(e)=>savearchiv(e,props.ProjectId,props.id,(props.archiv === "1" ? 0 : 1),props.setFetchData)}>{(props.archiv === "1" ? 'Restore ticket from archiv' : 'Archive ticket')} </NavDropdown.Item>
                            <NavDropdown.Item onClick={(e)=>savedelete(e,props.ProjectId,props.id,(props.delete === "1" ? 0 : 1),props.setFetchData)}>{(props.delete === "1" ? 'Restore ticket from trash' : 'Delete ticket')} </NavDropdown.Item>
                        </NavDropdown> </Col>
                        {/*<Col className={'col-1'} style={{marginTop:'-5px',padding:'0'}}>

                             <span className="material-icons float-right" onClick={() => (document.getElementById('cardITem'+props.id) as HTMLDivElement).style.display = 'none'}>
                                    expand_less
                                </span>

                                <br/>
                                <span className="material-icons float-right" onClick={() => (document.getElementById('cardITem'+props.id) as HTMLDivElement).style.display = 'block'}>
                                    expand_more
                                </span>

                        </Col>*/}
                        </Row>
                        <div>
                            <small style={{fontSize:'small'}}><strong>{props.id} | </strong></small>
                            <small style={{fontSize:'small'}}>{dateFormat(
                                props.date,
                                localStorage.getItem('locale'),
                                'us',
                                true,
                                false,
                                props.Store
                            )}</small> <small style={{fontSize:'small'}}>
                            <span style={{paddingLeft:'5px',paddingRight:'5px',borderRadius: '5px',color: (props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ?'white' : 'black'),background: (props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ? colorset[props.priorität].color : 'white')}}> P{(props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ? props.priorität : 0)} </span>
                            <span title={'now '+props.comment+' comments'}>
                            <span className="material-icons" style={{verticalAlign:'middle',width:'30px',color:'rgb(223, 176, 255)'}}>
                                mode_comment
                            </span>
                                <span style={{color:'white',marginLeft:'-25px',marginBottom:'5px'}}>{props.comment}</span>
                            </span>
                        </small>
                        </div>
                        <div>
                            <Stack direction="horizontal" gap={2}>
                                <span className="badge"
                                      style={{fontSize:'12px', fontWeight:'bolder',color:'rgb(16, 80, 183)',background:'white',border: '1px solid rgb(16, 80, 183)'}}>
                                    {props.state}
                                </span>
                                {StackBadgeHolder}
                            </Stack>
                        </div>



                    </Card.Title>
                    <Card.Text id={'cardITem'+props.id} style={{display:'none'}}>
                        <div>
                            <p className={'float-start'}>{props.text}</p>
                            <p className={'float-end'} style={{textAlign: 'right',color:colorProess}}>{props.now}%</p>
                            <br style={{clear:'both'}}></br>
                        </div>
                        <ProgressBar style={{height:'5px'}} variant={props.variant !== undefined && props.variant !== ''  ? props.variant : 'danger'} now={props.now} />
                        <br/>
                        {props.teamMember !== undefined && props.teamMember !== '' ?
                            <>
                                <Row>
                                    <Col md={8} xxl={8} xl={8} sm={8}>Team Member<br/>

                                        <AvatarGroup max={4} className={'float-start'} id={props.avatarID !== undefined && props.avatarID !== ''  ? props.avatarID+'avatarGroup' : 'avatarGroup'}>
                                            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                            <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                                            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                                            <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                                            <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
                                        </AvatarGroup>

                                        {/*  <Avatar className={'float-end teamMember '} style={{border: '1px solid #bdbdbd',background: 'white',color: 'blue'}}>
                                    <span className="material-icons">
                                        add
                                        </span>
                                            </Avatar>*/}
                                    </Col>
                                    <Col  md={4} xxl={4} xl={4} sm={4}><p style={{textAlign:'right'}}><br/>
                                        <span className="badge rounded-pill "
                                              style={{marginTop: '10px',backgroundColor: 'rgba('+props.daysColor+',0.4)',color:'rgba('+props.daysColor+')'}}>
                                    {props.daysLeft} days left</span>
                                    </p></Col>
                                </Row>
                            </>
                            :(<>
                                <Row>
                                    <Col>Status<br/>
                                        <span style={{color: color,textTransform:'uppercase'}}><strong>{props.status !== undefined && props.status !== ''  ? props.status : 'Open'}</strong></span></Col>
                                    <Col><p style={{textAlign:'right'}}><br/> <span className="badge rounded-pill "
                                                                                    style={{backgroundColor: 'rgba('+props.daysColor+',0.4)',color:'rgba('+props.daysColor+')'}}>
                                    {props.daysLeft} days left</span></p></Col>
                                </Row>
                            </>)}

                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    )
}
