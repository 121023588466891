import React from 'react'
import Login from './Login'
import Logout from './Logout'
import Signin from './Signin'
import ForgottPassword from "./ForgottPassword";

export const AuthPagesConfig = (value: any) => {
    return [
        {
            path: '/login',
            elements: <Login value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator',
                'merchant',
                'merchant_ma',
                'user',
                'host',
                'icymare',
                'engineer',
                'locksmith',
                'consultant'

            ]
        },
        {
            path: '/logout',
            elements: <Logout value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator',
                'merchant',
                'merchant_ma',
                'user',
                'host',
                'icymare',
                'engineer',
                'locksmith',
                'consultant'
            ]
        },
        {
            path: '/Signin',
            elements: <Signin value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator',
                'merchant',
                'merchant_ma',
                'user',
                'host',
                'icymare',
                'engineer',
                'locksmith',
                'consultant'
            ]
        },
        {
            path: '/forgottpassword',
            elements: <ForgottPassword value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator',
                'merchant',
                'merchant_ma',
                'user',
                'host',
                'icymare',
                'engineer',
                'locksmith',
                'consultant'
            ]
        },
        {
            path: '/passwort-new/:page',
            elements: <ForgottPassword value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator',
                'merchant',
                'merchant_ma',
                'user',
                'host',
                'icymare',
                'engineer',
                'locksmith',
                'consultant'
            ]
        }
    ]
}

export const AuthMenuConfig = (value: any) => {
    return [
        {
            name: 'Beiträge',
            route: '/Blog',
            icon: 'post_add',
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator',
                'merchant',
                'merchant_ma',
                'user',
                'host',
                'icymare',
                'engineer',
                'locksmith',
                'consultant'
            ]
        }
    ]
}
