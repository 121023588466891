import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Chart } from "react-google-charts";
import'../css/dashboardTicketSystem.css'
import CreateProjectCard, {CreateTaskCard} from "../components/CreateProjectCard";
import CreateBlock from "../components/CreateBlock";
import {ResponseDashbaordProject, ResponseDashbaordTask, ResponseDashboardProject} from "../../../API/response/ticket";
import '../css/timeline.css'
import {between2digits, Search} from "./MyProjects";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import MergeTwoObjecttoArray from "../../../../../@WUM/core/component/const/MergeTwoObjecttoArray";
import {itSupport} from "../../index";

const Dashboard = (props:{value:any}) => {
    const Store = useSelector((state: any) => state)

    const response = ResponseDashboardProject();
    const Project = ResponseDashbaordProject(0);
    const Tasks = ResponseDashbaordTask(0)
    const navigate = useNavigate();
    if (typeof response !== "boolean" && response?.length > 0) {
        const data = [
            ["", ""],
            ["Apr 04", 20000],
            ["Apr 05", 15000],
            ["Apr 06", 25000],
            ["Apr 07", 20000],
            ["Apr 08", 25000],
            ["Apr 09", 20000],
            ["Apr 10", 30000],
        ];


        let buildDashboardCardPRoject = [];
        if (Project !== true && typeof Project !== "boolean" && Project?.length > 0) {

            for (let x = 0; x < Project.length; x++) {
                let nowcal: number = (100 / (Project[x].allTask as number)) * (Project[x].doneTask as number)
                buildDashboardCardPRoject.push(
                    <CreateProjectCard Store={Store} id={Project[x].id} title={Project[x].title}
                                       now={(!isNaN(nowcal) ? nowcal.toFixed(0) : 0)}
                                       variant={between2digits((!isNaN(nowcal) ? nowcal : 0))}
                                       status={'Open'} ClassN={'col-6'}
                                       daysColor={'14, 170, 11'} daysLeft={12} allTask={Project[x].allTask}
                                       task={Project[x].doneTask} date={Project[x].created_at}/>
                )
            }
        }

        let buildDashboardCardTask = [];
        if (Tasks !== true && typeof Tasks !== "boolean" && Tasks?.length > 0) {

            for (let x = 0; x < Tasks.length; x++) {
                buildDashboardCardTask.push(<>
                    <CreateTaskCard Store={Store} ProjectId={Tasks[x].project_id} priorität={Tasks[x].priority}
                                    position={(Tasks[x].position !== null ? Tasks[x].position : x)}
                                    comment={Tasks[x].comment} ClassN={'col-6'} ColPadding={'0px 12px '}
                                    id={Tasks[x].task_id} avatarID={1}
                                    title={Tasks[x].regard} now={100} variant={'success'}
                                    status={'Open'} daysColor={'248, 98, 27'} daysLeft={2} task={1}
                                    allTask={1} archiv={Tasks[x].archiv}
                                    label={Tasks[x].support_label} delete={Tasks[x].delete}
                                    teamMember={true} date={Tasks[x].created_at} text={Tasks[x].instanz}
                                    background={(Tasks[x].instanz !== undefined && Tasks[x].instanz !== null && Tasks[x].instanz !== '' ? '#e5f1fd' : (Tasks[x].color !== undefined && Tasks[x].color !== null ? Tasks[x].color : 'white'))}/>
                </>)

            }
        }
        const options = {
            title: "Projects\nThis Months",
            hAxis: {title: "Year", titleTextStyle: {color: "#333"}},
            vAxis: {
                minValue: 0, maxValue: 40000, gridlines: {
                    count: 0
                }
            },
            chartArea: {width: "80%", height: "70%"},
            legend: {position: 'none'}
        };

         let Piedata = [ ];
         Piedata.push(["Task", "Hours per Day"]);
        let slices= [];
        let legendenholder = [];
        for(let cx =0;cx<response[0].AllColumnProject.length;cx++) {
            if(response[0].AllColumnProject[cx] !== null && response[0].AllColumnProject[cx] !== undefined) {
                let tmpData = [response[0].AllColumnProject[cx].name, response[0].ProjectColoumn[response[0].AllColumnProject[cx].name]]
                Piedata.push(tmpData);
                legendenholder.push(
                    <>
                        <div style={{borderBottom: '1px solid '+response[0].AllColumnProject[cx].color, marginBottom: '10px'}}>
                            <div style={{
                                borderLeft: '5px solid '+response[0].AllColumnProject[cx].color,
                                borderRadius: '0px',
                                paddingLeft: '10px',
                                borderBottomLeftRadius: '5px',
                                borderTopLeftRadius: '5px'
                            }}>
                                <p className={'m-0'}>{response[0].AllColumnProject[cx].name}</p>
                                <p className={'float-start m-0'}><strong>{response[0].ProjectColoumn[response[0].AllColumnProject[cx].name]}</strong></p><p className={'float-end m-0'}>
                                <strong>{((100 / (response[0].TotalActiveProjects as number) ) * (response[0].ProjectColoumn[response[0].AllColumnProject[cx].name] as number)).toFixed(1)}%</strong></p>
                                <br style={{clear: 'both', height: '1px'}}/>
                            </div>
                            <br/>
                        </div>
                    </>
                )
                slices.push({color: response[0].AllColumnProject[cx].color}) ;
            }

        }




        let AllPie = response[0].open + response[0].inProgress + response[0].complete;

        const Pieoptions = {
            title: "PROJECTS STATUS\n",
            pieHole: 0.6,
            chartArea: {width: "80%", height: "70%"},
            is3D: false,
            slices: slices,
            legend: {position: 'none'}
        };


        let PiedataTask = [ ];
        PiedataTask.push(["Task", "Hours per Day"]);

        let legendenholderTask = [];

        let slicesTask = [];

        for(let cx =0;cx<response[0].AllColumnTask.length;cx++) {
            if(response[0].AllColumnTask[cx] !== null && response[0].AllColumnTask[cx] !== undefined) {
                let tmpData = [response[0].AllColumnTask[cx].name, response[0].TaskColumn[response[0].AllColumnTask[cx].name]]
                PiedataTask.push(tmpData);
                legendenholderTask.push(
                    <>
                        <div style={{borderBottom: '1px solid '+response[0].AllColumnTask[cx].color, marginBottom: '10px'}}>
                            <div style={{
                                borderLeft: '5px solid '+response[0].AllColumnTask[cx].color,
                                borderRadius: '0px',
                                paddingLeft: '10px',
                                borderBottomLeftRadius: '5px',
                                borderTopLeftRadius: '5px'
                            }}>
                                <p className={'m-0'}>{response[0].AllColumnTask[cx].name}</p>
                                <p className={'float-start m-0'}><strong>{response[0].TaskColumn[response[0].AllColumnTask[cx].name]}</strong></p><p className={'float-end m-0'}>
                                <strong>{((100 / (response[0].totalTask as number) ) * (response[0].TaskColumn[response[0].AllColumnTask[cx].name] as number)).toFixed(1)}%</strong></p>
                                <br style={{clear: 'both', height: '1px'}}/>
                            </div>
                            <br/>
                        </div>
                    </>
                )

                    slicesTask.push({color: response[0].AllColumnTask[cx].color}) ;
                }

        }


        const PieoptionsTask = {
            title: "TASK STATUS\n",
            pieHole: 0.6,
            chartArea: {width: "80%", height: "70%"},
            is3D: false,
            slices: slicesTask,
            legend: {position: 'none'}
        };

        const totalPie = (
            <>
                <div className={'totalPie'}>
                    <h4>Total<br/>{response[0].TotalActiveProjects}</h4>
                </div>
            </>
        )

        const totalPieTask = (
            <>
                <div className={'totalPie'}>
                    <h4>Total<br/>{response[0].totalTask}</h4>
                </div>
            </>
        )

        const Legende = (
            <>
                <div className={'legende'}>
                    {legendenholder}

                </div>

            </>
        )

        const LegendeTask = (
            <>
                <div className={'legende'}>

                    {legendenholderTask}
                </div>

            </>
        )

        return (
            <>
                <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>
                    <Search navigate={navigate}/>
                    <Row>
                        <CreateBlock title={'New Project Past 30 days'} icon={'format_list_numbered'} number={response[0].last30daysProject}/>
                        <CreateBlock title={'Archived Projects'} icon={'history'} number={response[0].ArchivedProject}/>
                        <CreateBlock title={'Deleted Projects'} icon={'format_list_numbered'} number={response[0].DeletedProject}/>
                        <CreateBlock title={'Number of Tasks'} icon={'format_list_numbered'} number={response[0].totalTask}/>
                    </Row>
                    <Row style={{marginTop: '2em', marginLeft: '0px', marginRight: '0px'}}>
                        <Col className={'card'} style={{marginRight: '25px'}}>
                            <h5 style={{paddingTop:'10px'}}>Last 4 Created PROJECTS</h5>
                            <Row>
                            {buildDashboardCardPRoject}
                            </Row>
                            </Col>
                        <Col className={'card'}>
                            <Row>
                                <Col>
                                    {totalPie}
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="400px"
                                        data={Piedata}
                                        options={Pieoptions}
                                    /></Col>
                                <Col> {Legende}</Col>
                            </Row>

                        </Col>
                    </Row>
                    <Row style={{marginTop: '2em', marginLeft: '-12px', marginRight: '-12px'}}>
                        <CreateBlock title={'New Tasks Past 30 days'} border={"1px solid #23AAE1"} icon={'format_list_numbered'} number={response[0].last30daysTasks}/>
                        <CreateBlock title={'Archived Tasks'} border={"1px solid #23AAE1"} icon={'history'} number={response[0].ArchivedTask}/>
                        <CreateBlock title={'Deleted Tasks'} border={"1px solid #23AAE1"} icon={'format_list_numbered'} number={response[0].DeletedTask}/>
                    </Row>
                    <Row style={{marginTop: '2em', marginLeft: '0px', marginRight: '0px'}}>
                        <Col className={'card'} style={{marginRight: '25px',padding:'10px',border: "1px solid #23AAE1"}}>
                            <h5 style={{paddingTop:'10px'}}>Last 6 Created TASK</h5>
                            <Row>
                                {buildDashboardCardTask}
                            </Row>
                        </Col>
                        <Col className={'card'} style={{border: "1px solid #23AAE1"}}>
                            <Row>
                                <Col>
                                    {totalPieTask}
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="400px"
                                        data={PiedataTask}
                                        options={PieoptionsTask}
                                    /></Col>
                                <Col> {LegendeTask}</Col>
                            </Row>

                        </Col>
                    </Row>
                </div>
            </>
        )
    } else {
        return (<></>)
    }
}

export default Dashboard;


